import React from 'react'

import {
    action,
    computed,
    keys,
    observable,
    remove,
    values as mobxValues
} from 'mobx'
import { observer } from 'mobx-react'

import { Affix, List, Spin } from '~/components'

import Page from '~/layouts/main/page'

import { FieldsConfigForm } from '~/components'
import { Drawer } from '~/components'
import { DataFilter, FilterOption, FilterValue } from '~/components/data-filter'
import { Column, Grid } from '~/components/grid'
import { FormIcon } from '~/components/icon'
import IconButton from '~/components/icon-button'

import DictionaryStore from '~/dictionary/dictionary-store'

import PaymentDetailedForm from '../payment-detailed-form'

import { DetailedFieldsGroup } from '../../types'

import GridControls from './grid-controls'

import PaymentsPageStore from './payments-page-store'

import detailedStyles from '../../components/payment-detailed-form/styles.css'

import paymentsRes from '~/pages/payment-aft/res/index'
import res from './res/index'
import styles from './styles.css'

import {
    defaultMobileMenuConfig,
    MobileMenuConfig
} from '~/components/mobile-menu/mobile-menu'

import BrowserStore from '~/services/browser/browser-state'

import { InfinitiveList } from '~/components/list/infinitive-list'
import InfinitiveListStyles from '~/components/list/styles.css'

import ModalContainer, {
    ModalContext,
    ModalStyles
} from '~/components/modal/modal-container'

import MobileHeader from '~/components/modal/mobile-header'

import isEmpty from 'lodash/isEmpty'

interface BasePaymentInterface {
    invoiceID: string
    id: string
}

interface PaymentsPageProps<
    TE extends BasePaymentInterface,
    TS extends PaymentsPageStore<TE>
    > {
    store: TS
    dictionary: DictionaryStore
}

export abstract class PaymentsPage<
    TE extends BasePaymentInterface,
    TS extends PaymentsPageStore<TE>
    > extends React.Component<PaymentsPageProps<TE, TS>, {}> {
    public state = { paymentActionsVisible: false, scrollTopTrigger: 0 }
    public render() {
        return (
            <React.Fragment>
                <Page
                    title={this.pageTitle}
                    toolbar={this.toolbar}
                    toolbarClassName={styles.mobileToolbar}
                >
                    {this.summary}
                    <div className={styles.baseControlsContainer}>
                        {this.baseControls}
                        {this.dataFilter}
                    </div>
                    <div className={styles.clearElement}></div>
                    {!BrowserStore.isMobile && (
                        <div className={styles.gridControlsContainer}>
                            <Affix offsetTop={15}>{this.gridControls}</Affix>
                        </div>
                    )}
                    <div className={styles.gridCnt}>{this.grid}</div>
                    {this.detailedPaymentForm}
                    {this.dataFilterForm}
                    {this.customizeGridForm}
                </Page>
            </React.Fragment>
        )
    }

    protected abstract get title(): string

    protected get summary(): React.ReactNode {
        return undefined
    }

    protected get pageHeaderControls(): React.ReactNode {
        return undefined
    }

    protected get pageTitle(): React.ReactNode {
        return <span>{this.title}</span>
    }

    protected get baseControls(): React.ReactNode {
        return undefined
    }

    @computed
    protected get mobileMenuConfig(): MobileMenuConfig {
        return { ...defaultMobileMenuConfig }
    }
    protected get dataFilterOptions(): Array<FilterOption<TE>> {
        return undefined
    }

    protected get gridActions(): React.ReactNode {
        return undefined
    }

    protected get toolbar(): React.ReactNode {
        return undefined
    }

    protected abstract get columns(): Array<Column<TE>>

    protected abstract get paymentDetailedFields(): Array<
        DetailedFieldsGroup<TE>
    >

    protected abstract get detailedPaymentActions(): React.ReactNode

    @observable
    protected showDataFilterForm: boolean

    @observable
    protected showCustomizeGridForm: boolean

    @observable
    protected detailedPaymentReference?: number

    @observable
    protected detailedPayment?: TE

    protected paymentReferenceField = 'reference'

    protected get dataFilterForm() {
        return undefined
    }
    protected scrollDependedControls: string[] = [
        InfinitiveListStyles.spinningContainer
    ]

    @action.bound
    protected openDataFilterForm() {
        this.showDataFilterForm = true
    }

    @action.bound
    protected closeDataFilterForm() {
        this.showDataFilterForm = false
    }

    @action.bound
    protected openCustomizeGridForm() {
        this.showCustomizeGridForm = true
    }

    @action.bound
    protected closeCustomizeGridForm() {
        this.showCustomizeGridForm = false
    }

    @action.bound
    protected openPaymentDetailedForm(payment: TE) {
        if (BrowserStore.isMobile) {
            this.props.store.setSelectedPayments([payment.id])
        }
        this.props.store.setDetailedPayment(payment)
    }

    @action.bound
    protected closePaymentDetailedForm() {
        this.props.store.setDetailedPayment(null)
    }

    protected renderListItem = (item: TE) => {
        return <List.Item>renderListItem not defined</List.Item>
    }

    protected scrollToTop = () => {
        this.setState({ scrollTopTrigger: this.state.scrollTopTrigger + 1 })
    }

    protected get isActionVisible() {
        return false
    }

    private get gridControls() {
        return (
            <GridControls
                store={this.props.store}
                actions={this.gridActions}
                customize={this.openCustomizeGridForm}
            />
        )
    }

    private dataFilterContainer = observer(() => {
        const openFormButton = (
            <IconButton
                icon={FormIcon}
                dataSemanticId="filter-form"
                className={styles.dataFilterFormButton}
                tooltipTitle={res().filters}
                id="statement-filter-form"
                onClick={this.openDataFilterForm}
            />
        )

        return (
            <DataFilter<TE>
                placeholder={res().dataFilterPlaceholder}
                options={this.dataFilterOptions}
                values={this.props.store.filters}
                action={openFormButton}
            />
        )
    })

    private get dataFilter() {
        if (!this.dataFilterOptions || BrowserStore.isMobile) return undefined

        return <this.dataFilterContainer />
    }

    private gridConfigContainer = observer(() => {
        const { store } = this.props

        const columns = store.columns.map(column => {
            const data = this.columns.find(item => item.field === column.field)

            return {
                field: column.field,
                visible: column.visible,
                title: data && data.title
            }
        })

        return (
            <FieldsConfigForm
                values={columns}
                visible={this.showCustomizeGridForm}
                onClose={this.closeCustomizeGridForm}
                onSetFields={store.setColumnsOrder}
                pageSize={store.pageSize}
                onSetPageSize={store.setPageSize}
                sortable={true}
                customizablePageSize={true}
            />
        )
    })

    private get customizeGridForm() {
        return <this.gridConfigContainer />
    }

    private listContainer = observer(() => {
        const { store } = this.props

        return (
            <div
                className={styles.listInfiniteContainer}
                style={{ height: BrowserStore.windowHeight - 101 + 'px' }}
            >
                <InfinitiveList<TE>
                    dataSource={this.props.store.data}
                    loaded={!store.paymentsLoading}
                    dataLoader={store.load}
                    itemRenderer={this.renderListItem}
                    pageSize={store.pageSize}
                    scrollTopTrigger={this.state.scrollTopTrigger}
                ></InfinitiveList>
            </div>
        )
    })

    private gridContainer = observer(() => {
        const { store } = this.props

        const columns: Array<Column<TE>> = []

        const colMap = new Map(
            this.columns.map((col): [keyof TE, Column<TE>] => [col.field, col])
        )

        let horizontalWidth = 0
        store.visibleColumns.forEach(col => {
            const column = colMap.get(col.field)
            column.width = col.width
            horizontalWidth += column.width
            columns.push(column)
        })

        return (
            <Grid<TE>
                columns={columns}
                data={store.data}
                onColumnMove={store.moveColumn}
                onColumnResize={store.resizeColumn}
                rowKey={store.paymentKey as keyof TE}
                loading={store.paymentsLoading}
                onRowsSelect={store.setSelectedPayments}
                onRowClick={(record: TE) =>
                    this.openPaymentDetailedForm(record)
                }
                selectedRowKeys={store.selectedPaymentKeys}
                processingRowKeys={store.processingPaymentKeys}
                disableRowSelection={store.disableRowSelection}
                rowClassName="hint-section-4-step-6"
                scroll={{x: horizontalWidth}}
            />
        )
    })

    private get grid() {
        if (BrowserStore.isMobile) {
            return <this.listContainer />
        }
        return <this.gridContainer />
    }

    private detailedPaymentFormContainer = observer(() => {
        const detailedPayment = this.props.store.detailedPayment
        const store = this.props.store
        if (!detailedPayment) return null
        const paymentsFrom = (
            <PaymentDetailedForm
                visible={true}
                payment={detailedPayment}
                paymentReference={detailedPayment[this.paymentReferenceField]}
                actionsPane={this.detailedPaymentActions}
                fields={this.paymentDetailedFields}
                onClose={() => this.closePaymentDetailedForm()}
            />
        )
        if (BrowserStore.isMobile) {
            const paymentTitle = `${res().payment} : ${!isEmpty(detailedPayment.invoiceID)
                ? detailedPayment.invoiceID
                : 'none'
                }`
            const paymentActionsTrigger = this.isActionVisible
                ? () => this.setState({ paymentActionsVisible: true })
                : undefined
            return (
                <React.Fragment>
                    <ModalContainer
                        visible={detailedPayment ? true : false}
                        title={paymentTitle}
                        subTitle={
                            store.range
                                ? paymentsRes().collapseSummary(
                                    store.range.label
                                )
                                : ''
                        }
                        onClose={() => this.closePaymentDetailedForm()}
                        closable={true}
                        modalClass={ModalStyles.fullModal}
                        drawerClass={`${styles.paymentsForm} ${ModalStyles.fullDrawer}`}
                        headerControl={
                            <MobileHeader
                                title={paymentTitle}
                                onCancel={() => this.closePaymentDetailedForm()}
                                onOk={paymentActionsTrigger}
                                okElement={res().actions}
                            />
                        }
                    >
                        {paymentsFrom}
                    </ModalContainer>
                    <ModalContainer
                        visible={
                            detailedPayment && this.state.paymentActionsVisible
                        }
                        title={paymentTitle}
                        subTitle={
                            store.range
                                ? paymentsRes().collapseSummary(
                                    store.range.label
                                )
                                : ''
                        }
                        onClose={() =>
                            this.setState({ paymentActionsVisible: false })
                        }
                        closable={true}
                        headerControl={
                            <MobileHeader
                                title={undefined}
                                onCancel={() =>
                                    this.setState({
                                        paymentActionsVisible: false
                                    })
                                }
                            />
                        }
                    >
                        {this.detailedPaymentActions}
                    </ModalContainer>
                </React.Fragment>
            )
        } else {
            return (
                <Drawer
                    placement="right"
                    onClose={() => this.closePaymentDetailedForm()}
                    visible={true}
                    className={detailedStyles.form}
                    closable={false}
                >
                    {paymentsFrom}
                </Drawer>
            )
        }
    })

    private get detailedPaymentForm() {
        return <this.detailedPaymentFormContainer />
    }
}

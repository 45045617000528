import { fields, historyFields } from './data-fields'
import sortOptions from './sort-options'
import statementFormats, { StatementFormats } from './statement-formats'
import statementTypes, { StatementType } from '~/pages/statements/constants/statement-types'

export default {
    fields,
    sortOptions,
    statementFormats,
    statementTypes,
    historyFields
}
export { StatementFormats, StatementType }

import React from 'react'
import res from './res'

res.add('kk', {
    title: 'Төлемге сілтеме жасау',
    dataToSendLink: 'Сілтемені жіберуге арналған деректер',
    generalData: 'Жалпы мәліметтер',
    chooseStoreLabel: 'Дүкенді таңдаңыз',
    invoicePlaceholder: 'Инвойс нөмірін енгізіңіз',
    invoiceAltPlaceholder: 'Балама инвойс нөмірін енгізіңіз',
    accountIdPlaceholder: 'Клиент нөмірін енгізіңіз',
    paymentNotificationData: 'Төлем туралы хабарлама үшін мәліметтер',
    fieldErrorMessage: 'Осы өрісті тексеріңіз',
    generateButton: 'Қалыптастыру',
    applyButton: 'Қолдану',
    closeButton: 'Жабу',
    months: 'айлар',
    notRequired: 'міндетті емес',
    cancelButton: 'Бас тарту',
    withoutInstallment: 'Бөліп төлеу жоқ',
    installmentTooltip:
        'Сіз өз тауарларыңызды бөліп-бөліп сата аласыз. Ол үшін "Бөліп төлеу" қызметін қосу қажет',
    invalidShop: 'Дүкен таңдаңыз',
    invalidAccountId:
        'Сатып алушының сәйкестендіргішіі  8 ден 30 ға дейінгі таңбадан тұруы керек және әріптері болмауы керек',
    invalidAmount: 'Осы форматта кемінде 100 соманы енгізіңіз (100.00)',
    descriptionRequired: 'Сипаттама бос болмауы керек',
    descriptionMaxLength: 'Сипаттама таңбалар шегінен аспауы керек',
    invalidEmail: 'Дұрыс форматтағы электрондық поштаны енгізіңіз (example@email.co)',
    invalidOrderId:
        'Нөмір 8 мен 16 таңба арасында болуы керек және тек нөмір таңбаларынан тұруы керек',
    invalidPhone: 'Дұрыс форматтағы телефон нөмірін енгізіңіз',
    formItemTitles: {
        orderNumber: 'Тапсырыс нөмірі',
        invoiceAlt: 'Балама тапсырыс нөмірі',
        orderSum: 'Тапсырыс сомасы',
        accountId: 'Клиент сәйкестендіргіші',
        language: 'Хабарлама тілі',
        recieverEmail: 'Алушының Email поштасы',
        recieverPhone: 'SMS алушының телефон нөмірі',
        senderEmail: 'Хабарландыру үшін Email',
        senderPhone: 'Хабарландыру үшін телефон нөмірі',
        email: 'Email',
        phone: 'Телефон нөмірі',
        description: 'Тапсырыс сипаттамасы ',
        expirePeriod: 'Сілтеменің қолданылу мерзімі (күндер)'
    },
    confirmationTitle: (orderNumber: string) => (
        <div>
            Тапсырыс бойынша төлем сілтемесі {orderNumber} <br /> құрылды және жіберілді
        </div>
    ),
    clipBoardMessage: 'Сілтеме көшірілді',
    sumError: 'Соманы келесі форматта енгізіңіз: 100',
    duplicateOrderIdError:
        'Көрсетілген тапсырыс нөмірі бұрыннан бар. Басқа тапсырыс нөмірін енгізіңіз',
    serverError: 'Серверге сұрату салу кезіндегі қате',
    noShopsError:
        'Белсенді дүкендер табылмады. Қолдау қызметіне хабарласыңыз'
})

import { Moment } from 'moment'

import { ListRequest, ListResponse } from '~/api/contracts'
import { Chargeback } from '~/api'

export type AFTFilterableField =
    | 'id'
    | 'created_date'
    | 'shop_id'
    | 'invoice_id'
    | 'account_id'
    | 'payer_phone'
    | 'payer_email'
    | 'post_link'
    | 'amount'
    | 'card_mask'
    | 'client'
    | 'installment_id'
    | 'payout_date'
    | 'status_id'
    | 'result_code'

export type AFTSortableField =
    | 'id'
    | 'created_date'
    | 'status_id'
    | 'post_link'

export type AFTPaymentRequest = ListRequest<
    AFTFilterableField,
    AFTSortableField
>

export type AFTPaymentResponse = ListResponse<AFTPayment>

export interface AFTSummaryRequest {
    from?: Moment
    to?: Moment
}

export enum AFTStatus {
    // auth = 'AUTH',
    charged = 'CHARGE',
    // cancelled = 'CANCEL',
    // refunded = 'REFUND',
    // failed = 'FAILED',
    rejected = 'REJECT',
    new = 'NEW',
    any = 'ANY'
}

export enum AFTType {
    credit = 'Credit',
    pan = 'PAN',
    cardId = 'cardId'
}

export interface AFTPayment {
    id: string
    accountID: string // идентификатор клиента магазина
    amount: number // сумма транзакции
    currency: string // валюта транзакции
    accountId: string
    amountBonus: number
    approvalCode?: string
    cardMask: string
    chargeback: Chargeback
    invoiceID: string // номер заказа магазина (orderid)
    reference: string // ответ от карточной системы
    description: string // описание платежа
    fee: number // удержанная комиссия
    language: string // язык
    createdDate: Date // дата создания
    dateTime: Date // дата авторизации

    ipCity: string // город
    ipCountry: string // страна
    ipDistrict: string // район
    ipLatitude: number // широта
    ipLongitude: number // долгота
    ipRegion: string // регион
    invoiceId: string
    invoiceIdAlt?: string

    email: string // почта клиента
    senderEmail: string // почта клиента
    ip: string // ip клиента
    name: string // имя на карте
    phone: string // номер телефона клиента
    payerName: string
    payerPhone: string
    paymentType?: string
    payoutAmount: number
    payoutDate: Date
    purpose?: string
    resultMessage: string
    status: AFTStatus
    secure: boolean // транзакция с 3D
    reason: string // Сообщение ошибки если оплата не прошла
    code: AFTStatus // статус

    senderCardMask: string // маска карты отправителя
    senderCardType?: string // тип карты отправителя
    senderIssuer: string // банк эмитент отправителя
    // senderCardID: string //  Sender Card ID
    // senderOwID: string // Sender Ow ID
    senderTransferType?: string // Метод платежа pan | halykId | applePay | samsungPay

    receiverCardMask: string // маска карты получателя
    receiverCardType?: string // тип карты получателя
    receiverIssuer: string // банк эмитент получателя
    // receiverCardID: string //  Receiver Card ID
    // receiverOwID: string // Receiver Ow ID
    // receiverTransferType?: string // Метод платежа pan | halykId | applePay | samsungPay

    data: any // дополнительные поля

    //    orgAmount: number

    //    amountBonus: number // сумма в бонусах
    //    authDate: Date // дата авторизации
    //    chargeDate: Date // дата подтверждения
    //    client: string // клиент

    //    installment_id: string // период рассрочки

    //    issuerBankCountry: string // страна банка эмитента

    //    merchant: string // мерчант
    //    postLink: string // ссылка отправки данных по транзакции
    orgAmount: number
    orderId: number
    postLinkStatus: boolean // был ли postlink успешным

    //    payoutAmount: number // сумма возмещения
    //    payoutDate: Date // дата возмещения

    //    shop: string // магазин
    //    shopIsCreditCancelWithTransfer: boolean // признак частичного возврата

    //    template: string // имя шаблона платежной страницы

    //    transactionTypes?: string[]
    //    qr?: boolean // транзакция с QR
    user?: string
    user_ip?: string

}

export interface AFTSummary {
    currency: string
    byStatus: {
        authorized: {
            count: number
            amount: number
        }
        charged: {
            count: number
            amount: number
        }
        cancelled: {
            count: number
            amount: number
        }
        refunded: {
            count: number
            amount: number
        }
    }
    byPaymentSystem: {
        visa: {
            count: number
            amount: number
        }
        masterCard: {
            count: number
            amount: number
        }
        amex: {
            count: number
            amount: number
        }
        other: {
            count: number
            amount: number
        }
    }
    timeline: AFTTimelineItem[]
}

export interface AFTTimelineItem {
    time: Date
    amount: number
    count: number
}

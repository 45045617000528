import React, { ReactNode } from 'react'
import { observer } from 'mobx-react'
import Page from '~/layouts/main/page'
import res from './res'
import styles from './styles.css'
import ChargebackStore from '~/pages/chargeback/chargeback-store'
import { Button, Input, InputItem } from '~/components'
import { Form, Row, Col } from 'antd'
import { getProfile } from '~/api'


@observer
export default class Chargeback extends React.Component<{ store: ChargebackStore }, {}> {
    public render() {

        const { store } = this.props

        return (
            <>
                <Page
                    title={
                        <div>
                            <div> {res().title} </div>
                        </div>
                    }
                >
                    <div className={`${styles.content}`}>
                        <div className={styles.infoBlock}>
                            <div className={styles.info}>
                                <div className={styles.infoTitle}>{res().infoTitle}</div>
                                <div className={styles.infoText}> {res().infoText}</div>
                            </div>
                            <div className={styles.infoImage}></div>
                        </div>
                        <div className="setting">
                            <div className={styles.title}> {res().settingTitle}</div>
                            {}
                            <Form className={styles.form}>
                                {!store.isEdited &&
                                    <Row className={`${styles.rowForm} ${styles.unactive}`}>
                                        <Col md={{ span: 10 }} xs={{ span: 24 }}>
                                            <div className={styles.inputItem}>
                                                <div className={styles.inputLabel}>{res().labels.activeUrl}</div>
                                                <div className={styles.inputValue}>{store.chargebackUrl.value}</div>
                                            </div>
                                        </Col>
                                        <Col md={{ span: 10 }} xs={{ span: 24 }}>
                                            <div className={styles.inputItem}>
                                                <div className={styles.inputLabel}>{res().labels.activeSecret}</div>
                                                <div className={styles.inputValue}>{store.sharedSecret.value}</div>
                                            </div>
                                        </Col>
                                        <Col md={{ span: 4 }} xs={{ span: 24 }}>
                                            <Button
                                                className={styles.saveBtn}
                                                size="large"
                                                type={'primary'}
                                                disabled={!store.isModified}
                                                onClick={store.editDate}
                                            >
                                                {res().labels.edit}
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                                {store.isEdited &&
                                    <Row className={`${styles.rowForm}`}>
                                        <Col md={{ span: 10 }} xs={{ span: 24 }}>
                                            <InputItem
                                                trigger={'onChange'}
                                                label={res().labels.chargebackUrl}
                                                store={store.chargebackUrl}
                                                itemProps={{
                                                    className: styles.rowItem
                                                }}
                                                placeholder={
                                                    res().labels.chargebackUrlPlaceholder
                                                }
                                                inputProps={{
                                                    name: 'chargeback_url',
                                                    value: store.chargebackUrl.value
                                                }}
                                            />
                                        </Col>
                                        <Col md={{ span: 10 }} xs={{ span: 24 }}>
                                            <InputItem
                                                trigger={'onChange'}
                                                label={res().labels.sharedSecret}
                                                store={store.sharedSecret}
                                                itemProps={{
                                                    className: styles.rowItem
                                                }}
                                                placeholder={
                                                    res().labels.sharedSecretPlaceholder
                                                }
                                                inputProps={{
                                                    name: 'shared_secret',
                                                    value: store.sharedSecret.value
                                                }}
                                            />
                                        </Col>
                                        <Col md={{ span: 4 }} xs={{ span: 24 }}>
                                            <Button
                                                className={styles.saveBtn}
                                                size="large"
                                                type={'primary'}
                                                disabled={!store.isModified}
                                                onClick={store.updateData}
                                            >
                                                {res().labels.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        </div>
                    </div>
                </Page>
            </>
        )
    }
}

import { PaymentStatus } from '~/api'

import res from './res'

res.add('en', {
    loading: 'Loading statistics ...',
    failed: 'Statistics is unavailable :(',
    statuses: {
        [PaymentStatus.auth]: 'Pending',
        [PaymentStatus.charged]: 'Charged',
        [PaymentStatus.cancelled]: 'Cancelled',
        [PaymentStatus.rejected]: 'Cancelled',
        [PaymentStatus.refunded]: 'Refunded',
        [PaymentStatus.failed]: 'Failed',
        [PaymentStatus.new]: 'New',
        [PaymentStatus.any]: 'Any',
        [PaymentStatus.CANCEL_OLD]: 'Cancel old',
        [PaymentStatus.TIMEOUT_3D]: '3DS Cancelled',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'In progress',
        [PaymentStatus.PENDING]: 'Pending',
        [PaymentStatus.SUCCESS]: 'Success',
        [PaymentStatus.VERIFIED]: 'Verified'

    }
})

import { Moment } from 'moment'
import { FrequencyType } from '~/api/statements'
import res from './res'

res.add('kk', {
    manualGenerationTitle: 'Үзінді көшірме жасау',
    scheduleTitle: (scheduleType: FrequencyType) =>
        `${scheduleType === FrequencyType.daily
            ? 'Күнделікті'
            : scheduleType === FrequencyType.weekly
                ? 'Апта сайынғы'
                : 'Ай сайынғы'
        } үзінді көшірме параметрі`,
    periodTitle: 'Кезең',
    emailsTitle: 'Алушылардың электрондық пошталары',
    emailRequired: 'Қатысушының электрондық пошта мекенжайын енгізіңіз',
    invalidEmail: 'Қате email пішімі',
    formatTitle: 'Формат',
    type: 'Түрі',
    refund: 'Өтемақы',
    transaction: 'Транзакция',
    generateButton: 'Қалыптастыру',
    applyButton: 'Қолдану',
    cancelButton: 'Бас тарту',
    active: 'Іске қосылды',
    formatZip: 'Бұл өріс таңдалған кезде, қалыптастыру zip форматында орын алады',
    cols: {
        email: 'Email',
        control: 'Басқару',
        controlsTooltip: {
            add: 'Қосу',
            edit: 'Өңдеу',
            save: 'Қолдану',
            cancel: 'Бас тарту',
            delete: 'Жою'
        }
    }
})

import {
    action,
    computed,
    entries,
    isObservableArray,
    keys,
    observable,
    ObservableMap,
    reaction,
    remove,
    runInAction,
    set,
    toJS,
    values as mobxValues
} from 'mobx'

import amplitude from 'amplitude-js'

import { camelCaseToSnakeCase, move } from '~/utils'

import storage from '~/services/storage'

import { ApiResponse, AFTType, refundCreditAFT, AFTStatus /* aft @Dos */ } from '~/api'
import { PageSize, SearchParameter } from '~/api/contracts'
import { FilterValue, FilterValues } from '~/components/data-filter'
import { AmountsByCurrencies } from '~/pages/payment-aft/types'

import { noThrow } from '~/utils/control-flow'

import notification from '~/utils/message'
import { userError } from '~/utils/user-error'

import { RangePopoverValue } from '~/components/range-popover'
import res from './res'

import { LoadRequestInterface } from '~/components/list/infinitive-list'

import isNil from 'lodash/isNil'

interface ColumnPref<T> {
    field: keyof T
    visible: boolean
    width?: number
}

export interface PaymentFiltersInterface<T> {
    values: ObservableMap<keyof T, { [key in keyof FilterValue<T>]: any }>
    dateRange: RangePopoverValue
    installments: string[]
}
const filtersOperatorsNamingMap = {
    startsWith: 'like',
    endsWith: 'like',
    less: '<',
    greater: '>',
    lessOrEqual: '<=',
    greaterOrEqual: '>=',
    equals: '=',
    in: 'in'
}

export abstract class PaymentsPageStore<T> {
    protected constructor() {
        this.setup()

        this.setupPrefs()

        reaction(
            () => this.visibleColumns.map(col => col.field),
            () => this.normalizeWidths(),
            {
                fireImmediately: true
            }
        )
    }

    public disableRowSelection: boolean = false
    public paymentKey = 'id'

    public referenceKey = 'reference'

    @observable
    public pageInfo: {
        from: number
        to: number
        total: number
    }

    @observable
    public pageSize: PageSize = 20

    @observable
    public pageIndex = 0

    @observable
    public showPaymentsDetails = false

    @observable
    public filters: FilterValues<T> = {}

    @observable
    public columns: Array<ColumnPref<T>>

    @observable
    public sortField: keyof T

    @observable
    public sortDirection: 'asc' | 'desc'

    @observable
    public summaryLoading: boolean

    @observable
    public paymentsLoading: boolean

    @observable
    public summaryFailed: boolean

    @observable
    public paymentsFailed: boolean

    @observable
    public actionInProgress: boolean = false

    @observable.ref
    public data: T[]

    @computed
    public get visibleColumns() {
        return this.columns.filter(col => col.visible)
    }

    @observable
    public selectedPaymentKeys: string[] = []

    @observable
    public processingPaymentKeys: string[] = []

    @observable
    public errorPaymentKeys: string[] = []

    @observable
    public detailedPayment?: T

    @observable.ref
    public range: RangePopoverValue

    @observable
    public statusFilter: AFTStatus /* aft @Dos было PaymentStatus */

    @observable
    public installments: string[] = []

    public get selectedPaymentCount() {
        return this.selectedPaymentKeys.length
    }

    public abstract getPayments(request)

    public abstract getPaymentsByIds(ids: string[])

    @computed
    public get selectedPayments() {
        if (!this.data) return []

        const selectedKeys = this.selectedPaymentKeys.reduce(
            (setted, key) => setted.add(key),
            new Set()
        )

        return this.data.filter(payment =>
            selectedKeys.has(payment[this.paymentKey])
        )
    }

    public abstract get selectedPaymentAmounts(): AmountsByCurrencies

    @action.bound
    public reload() {
        this.load()
    }

    @action.bound
    public goFirstPage() {
        this.pageIndex = 0
    }

    @action.bound
    public goPrevPage() {
        if (this.pageIndex > 0) {
            this.pageIndex--
        }
    }

    @action.bound
    public goNextPage() {
        if (!this.pageInfo || this.pageIndex >= this.lastPage) return

        this.pageIndex++
    }

    @action.bound
    public goLastPage() {
        if (!this.pageInfo) return

        this.pageIndex = this.lastPage
    }

    @action.bound
    public moveColumn(index: number, newIndex: number) {
        if (index === newIndex) return

        index = this.columns.findIndex(
            col => col.field === this.visibleColumns[index].field
        )
        newIndex = this.columns.findIndex(
            col => col.field === this.visibleColumns[newIndex].field
        )

        move(this.columns, index, newIndex)
    }

    @action.bound
    public resizeColumn(index: number, newWidth: number) {
        const columns = this.visibleColumns

        const delta = newWidth - columns[index].width

        let s = 0
        for (let i = index + 1; i < columns.length; ++i) {
            s += columns[i].width
        }

        columns[index].width = newWidth

        // todo: improve calculations - re-calc k after each iteration
        const k = (s - delta) / s
        for (let i = index + 1; i < columns.length; ++i) {
            columns[i].width *= k
        }
    }

    @action.bound
    public setColumnsOrder(
        fields: Array<{ field: keyof T; visible: boolean }>
    ) {
        const map = this.columnMap

        this.columns = fields.map(col => {
            const column = map.get(col.field)

            column.visible = col.visible

            return column
        })
    }

    @action.bound
    public setSelectedPayments(paymentsKeys: string[]) {
        this.selectedPaymentKeys = paymentsKeys
    }

    @action.bound
    public setErrorPayments(paymentsKeys: string[]) {
        // this.errorPaymentKeys.push([...paymentsKeys])
    }

    @action.bound
    public setDetailedPayment(payment?: T) {
        this.detailedPayment = payment || null
    }

    @action.bound
    public clearSelectedPayments() {
        this.setSelectedPayments([])
    }

    @action.bound
    public setPageSize(size: PageSize) {
        this.pageIndex = Math.floor((this.pageSize * this.pageIndex) / size)

        this.pageSize = size
    }

    @action.bound
    public setSort(field: keyof T, direction: 'asc' | 'desc') {
        this.sortField = field
        this.sortDirection = direction
    }

    @action.bound
    public setRange(value: RangePopoverValue) {
        if (value && value.range && value.range[0] && value.range[1]) {
            this.range = value
        }
    }

    @action.bound
    public setInstallments(value: string[]) {
        if (value && value.length > 0) {
            this.installments = value
        }
    }
    @action.bound
    public clearFilters() {
        mobxValues(this.filters).forEach(field => {
            if (!field) return
            keys(field).forEach(operator => remove(field, operator))
        })
    }

    public prepareFilter(
        filters: FilterValues<T>,
        filterParams: PaymentFiltersInterface<T>
    ) {
        return filters
    }

    public isFilterEmpty(filterParams: PaymentFiltersInterface<T>) {
        return filterParams.values.size === 0
    }
    public isEmptyFilterParam(value: any) {
        return isNil(value) || !value.length
    }

    @action.bound
    public applyFilters(
        filterParams: PaymentFiltersInterface<T>,
        overwrite: boolean = false
    ) {
        const filters: FilterValues<T> = {} as FilterValues<T>
        filterParams.values.forEach((value, field) => {
            if (this.isEmptyFilterParam(value)) {
                filters[field] = value
            }
        })

        this.prepareFilter(filters, filterParams)
        if (this.isFilterEmpty(filterParams)) {
            this.clearFilters()
            this.setInstallments([])
        } else {
            if (overwrite) {
                this.clearFilters()
            }
            set(this.filters, filters)
            this.setRange(filterParams.dateRange)
            this.setInstallments(filterParams.installments)
        }
    }

    @computed
    public get activeFiltersCount(): number {
        const filters = this.filters
        const filterKeys = Object.keys(filters).filter(
            key =>
                key !== 'postLinkStatus' && mobxValues(filters[key]).length > 0
        )
        return filterKeys.length
    }

    public abstract load(requestParams?: LoadRequestInterface)

    protected abstract setup()

    protected abstract get storageKey(): string

    protected get prefs() {
        return undefined
    }

    protected set prefs(value) {
        // override
    }

    protected addFiltersToSearchParameters<TF extends SearchParameter<any>>(
        searchParameters: TF[]
    ) {
        entries(this.filters).forEach(filter => {
            const [field, conditions] = filter
            entries(conditions).forEach(condition => {
                const [operator, value] = condition

                if (isNil(value) && value !== 0) {
                    return
                }

                let valueParam = value

                switch (operator) {
                    case 'startsWith':
                        valueParam = `${value}%`
                        break

                    case 'endsWith':
                        valueParam = `%${value}`
                        break
                }

                const method = filtersOperatorsNamingMap[operator]
                const valueParamAdapted = isObservableArray(valueParam)
                    ? valueParam
                    : [valueParam]
                if (method) {
                    searchParameters.push({
                        name: camelCaseToSnakeCase(field),
                        method,
                        searchParameter: valueParamAdapted
                    } as TF)
                }
            })
        })

        return searchParameters
    }

    @action.bound
    protected async process(
        apiCall: (id: string, amount?: number, orderId?: number) => Promise<ApiResponse<void>>,
        operationTitle: string,
        itemErrorTitle: (itemId) => string,
        paymentsKeys?: string[],
        amount?: number,
        orderId?: number
    ) {
        const selection = paymentsKeys || [...this.selectedPaymentKeys]
        const selectedPayments: any[] = this.selectedPayments
        let filteredSelection = []
        let selectedCreditPayments = []

        const selectedReferences = selection.map(
            id =>
                this.data.find(payment => payment[this.paymentKey] === id)[
                this.referenceKey
                ]
        )

        this.processingPaymentKeys = this.processingPaymentKeys.concat([
            ...selection
        ])

        if (selectedPayments.length > 0) {
            selectedCreditPayments = selectedPayments.filter(item => item.senderTransferType === AFTType.credit)

            if (selectedCreditPayments?.length > 0) {
                selectedCreditPayments.forEach(payment => {
                    filteredSelection = selection.filter(item => item !== payment.id)
                })
            } else {
                filteredSelection = selection
            }
        }

        this.selectedPaymentKeys = []
        this.actionInProgress = true
        noThrow(async () => {
            let results
            let creditRefundResults = []

            if (selectedPayments.length > 0) {
                if (selectedCreditPayments?.length > 0) {
                    creditRefundResults = await Promise.all(
                        selectedCreditPayments.map(payment => refundCreditAFT(payment.invoiceId, payment.amount))
                    )
                }
                results = await Promise.all(
                    filteredSelection.map(id => apiCall(id, amount))
                )
            } else {
                if (amount && orderId) {
                    results = await Promise.all(
                        selection.map(id => refundCreditAFT(orderId, amount))
                    )
                } else {
                    results = await Promise.all(
                        selection.map(id => apiCall(id, amount))
                    )
                }
            }

            if (creditRefundResults.length > 0) {
                results.push(...creditRefundResults)
            }

            const successResultsCount = results.filter(result => !result.error)
                .length
            if (!(results[0] instanceof Error)) {
                if (
                    successResultsCount &&
                    results.length === successResultsCount
                ) {
                    notification.info(
                        res().operationsResult(
                            results.length,
                            successResultsCount
                        ),
                        operationTitle
                    )
                }
            }

            results.forEach(({ result, error }, index) => {
                if (error) {
                    notification.error(
                        userError(error),
                        itemErrorTitle(selectedReferences[index])
                    )
                    amplitude
                        .getInstance()
                        .logEvent(
                            'error_occurred_when_cancelling_payment',
                            error
                        )
                }
            })
            await this.reloadPaymentsByIds(selection)

            runInAction(() => {
                this.processingPaymentKeys = this.processingPaymentKeys.filter(
                    item => selection.indexOf(item) < 0
                )
                this.actionInProgress = false
            })
        })
    }

    @action
    private async reloadPaymentsByIds(ids: string[]) {
        const { result: reloadResult } = await this.getPaymentsByIds(ids)

        const { paymentKey } = this

        if (reloadResult && reloadResult && reloadResult.records) {
            const { data, detailedPayment } = this

            reloadResult.records.forEach(payment => {
                const operationIndex = data.findIndex(
                    item => item[paymentKey] === payment[paymentKey]
                )

                if (operationIndex >= 0) {
                    data[operationIndex] = payment
                }

                if (
                    detailedPayment &&
                    detailedPayment[paymentKey] === payment[paymentKey]
                ) {
                    this.setDetailedPayment(payment)
                }
            })
        }
    }

    private getPlainPrefs = () =>
        toJS(
            observable({
                columns: this.columns,
                pageSize: this.pageSize,
                sortField: this.sortField,
                sortDirection: this.sortDirection,
                range: this.range,
                ...this.prefs
            })
        )

    private setupPrefs() {
        const value = storage.get(this.storageKey)

        if (value) {
            const {
                columns,
                pageSize,
                sortField,
                sortDirection,
                ...rest
            } = value

            if (columns) {
                this.setupColumnPrefs(columns)
            }

            if (pageSize) {
                this.pageSize = pageSize
            }

            if (sortField && sortDirection) {
                this.sortField = sortField
                this.sortDirection = sortDirection
            }

            this.prefs = rest
        }

        reaction(this.getPlainPrefs, prefs =>
            storage.set(this.storageKey, prefs)
        )
    }

    private setupColumnPrefs(prefs: Array<ColumnPref<T>>) {
        const map = this.columnMap

        const newCols: Array<ColumnPref<T>> = []

        prefs.forEach(col => {
            const c = map.get(col.field)
            if (!c) return
            newCols.push(c)
            map.delete(col.field)
            c.width = col.width
            c.visible = col.visible
        })

        this.columns = newCols.concat(Array.from(map.values()))
    }


    private normalizeWidths() {
        const width = this.visibleColumns.reduce(
            (sum, col) => sum + (col.width || 150),
            0
        )

        // if (width !== 100) {
        //     this.visibleColumns.forEach(
        //         col => (col.width = (100 * (col.width || 10)) / width)
        //     )
        // }
    }

    @computed
    private get columnMap() {
        return new Map(
            this.columns.map((col): [keyof T, ColumnPref<T>] => [
                col.field,
                col
            ])
        )
    }

    @computed
    private get lastPage() {
        const n = this.pageInfo.total / this.pageSize

        return Math.floor(n) - (n % 1 === 0 ? 1 : 0)
    }
}

export default PaymentsPageStore

import { computed } from 'mobx'

import { EpayPayment } from '~/api'

import strict from '~/utils/strict'

import { SortOption } from '../../components/sort-config-select'

import res from '../res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{ title: string; items: Array<SortOption<EpayPayment>> }>
    >([
        {
            title: res().sortOptions.date,
            items: [
                {
                    field: 'createdDate',
                    direction: 'desc',
                    title: titles.desc.date
                },
                {
                    field: 'createdDate',
                    direction: 'asc',
                    title: titles.asc.date
                }
            ]
        },
        {
            title: res().sortOptions.postLink,
            items: [
                {
                    field: 'postLinkStatus',
                    direction: 'asc',
                    title: titles.asc.postLink
                },
                {
                    field: 'postLinkStatus',
                    direction: 'desc',
                    title: titles.desc.postLink
                }
            ]
        },
        {
            title: res().sortOptions.statusId,
            items: [
                {
                    field: 'statusId',
                    direction: 'asc',
                    title: titles.asc.statusId
                },
                {
                    field: 'statusId',
                    direction: 'desc',
                    title: titles.desc.statusId
                }
            ]
        },
        {
            title: res().sortOptions.orgAmount,
            items: [
                {
                    field: 'orgAmount',
                    direction: 'asc',
                    title: titles.asc.orgAmount
                },
                {
                    field: 'orgAmount',
                    direction: 'desc',
                    title: titles.desc.orgAmount
                }
            ]
        },
        {
            title: res().sortOptions.amount,
            items: [
                {
                    field: 'amount',
                    direction: 'asc',
                    title: titles.asc.amount
                },
                {
                    field: 'amount',
                    direction: 'desc',
                    title: titles.desc.amount
                }
            ]
        },
        {
            title: res().sortOptions.shopId,
            items: [
                {
                    field: 'shopId',
                    direction: 'asc',
                    title: titles.asc.shopId
                },
                {
                    field: 'shopId',
                    direction: 'desc',
                    title: titles.desc.shopId
                }
            ]
        },
        {
            title: res().sortOptions.amountBonus,
            items: [
                {
                    field: 'amountBonus',
                    direction: 'asc',
                    title: titles.asc.amountBonus
                },
                {
                    field: 'amountBonus',
                    direction: 'desc',
                    title: titles.desc.amountBonus
                }
            ]
        },
        {
            title: res().sortOptions.approvalCode,
            items: [
                {
                    field: 'approvalCode',
                    direction: 'asc',
                    title: titles.asc.approvalCode
                },
                {
                    field: 'approvalCode',
                    direction: 'desc',
                    title: titles.desc.approvalCode
                }
            ]
        },
        {
            title: res().sortOptions.accountId,
            items: [
                {
                    field: 'accountId',
                    direction: 'asc',
                    title: titles.asc.accountId
                },
                {
                    field: 'accountId',
                    direction: 'desc',
                    title: titles.desc.accountId
                }
            ]
        },
        {
            title: res().sortOptions.cardMask,
            items: [
                {
                    field: 'cardMask',
                    direction: 'asc',
                    title: titles.asc.cardMask
                },
                {
                    field: 'cardMask',
                    direction: 'desc',
                    title: titles.desc.cardMask
                }
            ]
        },
        {
            title: res().sortOptions.chargeback,
            items: [
                {
                    field: 'isChargeback',
                    direction: 'asc',
                    title: titles.asc.chargeback
                },
                {
                    field: 'isChargeback',
                    direction: 'desc',
                    title: titles.desc.chargeback
                }
            ]
        },
        {
            title: res().sortOptions.description,
            items: [
                {
                    field: 'description',
                    direction: 'asc',
                    title: titles.asc.description
                },
                {
                    field: 'description',
                    direction: 'desc',
                    title: titles.desc.description
                }
            ]
        },
        {
            title: res().sortOptions.payerEmail,
            items: [
                {
                    field: 'payerEmail',
                    direction: 'asc',
                    title: titles.asc.payerEmail
                },
                {
                    field: 'payerEmail',
                    direction: 'desc',
                    title: titles.desc.payerEmail
                }
            ]
        },
        {
            title: res().sortOptions.fee,
            items: [
                {
                    field: 'fee',
                    direction: 'asc',
                    title: titles.asc.fee
                },
                {
                    field: 'fee',
                    direction: 'desc',
                    title: titles.desc.fee
                }
            ]
        },
        {
            title: res().sortOptions.invoiceId,
            items: [
                {
                    field: 'invoiceId',
                    direction: 'asc',
                    title: titles.asc.invoiceId
                },
                {
                    field: 'invoiceId',
                    direction: 'desc',
                    title: titles.desc.invoiceId
                }
            ]
        },
        {
            title: res().sortOptions.invoiceIdAlt,
            items: [
                {
                    field: 'invoiceIdAlt',
                    direction: 'asc',
                    title: titles.asc.invoiceIdAlt
                },
                {
                    field: 'invoiceIdAlt',
                    direction: 'desc',
                    title: titles.desc.invoiceIdAlt
                }
            ]
        },
        {
            title: res().sortOptions.payerName,
            items: [
                {
                    field: 'payerName',
                    direction: 'asc',
                    title: titles.asc.payerName
                },
                {
                    field: 'payerName',
                    direction: 'desc',
                    title: titles.desc.payerName
                }
            ]
        },
        {
            title: res().sortOptions.payerPhone,
            items: [
                {
                    field: 'payerPhone',
                    direction: 'asc',
                    title: titles.asc.payerPhone
                },
                {
                    field: 'payerPhone',
                    direction: 'desc',
                    title: titles.desc.payerPhone
                }
            ]
        },
        {
            title: res().sortOptions.paymentType,
            items: [
                {
                    field: 'paymentType',
                    direction: 'asc',
                    title: titles.asc.paymentType
                },
                {
                    field: 'paymentType',
                    direction: 'desc',
                    title: titles.desc.paymentType
                }
            ]
        },
        {
            title: res().sortOptions.payoutAmount,
            items: [
                {
                    field: 'payoutAmount',
                    direction: 'asc',
                    title: titles.asc.payoutAmount
                },
                {
                    field: 'payoutAmount',
                    direction: 'desc',
                    title: titles.desc.payoutAmount
                }
            ]
        },
        {
            title: res().sortOptions.payoutDate,
            items: [
                {
                    field: 'payoutDate',
                    direction: 'asc',
                    title: titles.asc.payoutDate
                },
                {
                    field: 'payoutDate',
                    direction: 'desc',
                    title: titles.desc.payoutDate
                }
            ]
        },
        {
            title: res().sortOptions.purpose,
            items: [
                {
                    field: 'purpose',
                    direction: 'asc',
                    title: titles.asc.purpose
                },
                {
                    field: 'purpose',
                    direction: 'desc',
                    title: titles.desc.purpose
                }
            ]
        },
        {
            title: res().sortOptions.reference,
            items: [
                {
                    field: 'reference',
                    direction: 'asc',
                    title: titles.asc.reference
                },
                {
                    field: 'reference',
                    direction: 'desc',
                    title: titles.desc.reference
                }
            ]
        },
        {
            title: res().sortOptions.resultMessage,
            items: [
                {
                    field: 'resultMessage',
                    direction: 'asc',
                    title: titles.asc.resultMessage
                },
                {
                    field: 'resultMessage',
                    direction: 'desc',
                    title: titles.desc.resultMessage
                }
            ]
        },
        {
            title: res().sortOptions.qrReference,
            items: [
                {
                    field: 'qrReference',
                    direction: 'asc',
                    title: titles.asc.qrReference
                },
                {
                    field: 'qrReference',
                    direction: 'desc',
                    title: titles.desc.qrReference
                }
            ]
        },
        {
            title: res().sortOptions.user,
            items: [
                {
                    field: 'user',
                    direction: 'asc',
                    title: titles.asc.user
                },
                {
                    field: 'user',
                    direction: 'desc',
                    title: titles.desc.user
                }
            ]
        },
        {
            title: res().sortOptions.userIp,
            items: [
                {
                    field: 'userIp',
                    direction: 'asc',
                    title: titles.asc.userIp
                },
                {
                    field: 'userIp',
                    direction: 'desc',
                    title: titles.desc.userIp
                }
            ]
        }
    ])
})

export default options
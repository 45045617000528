import res from './res'

import { P2PStatus } from '~/api'

res.add('kk', {
    title: 'ePay аударымдар',
    ranges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан',
        customPeriod: 'көрсетілген кезең'
    },
    anyPaymentStatus: 'Кез келген',
    paymentStatuses: {
        // [P2PStatus.auth]: 'Күтеді',
        [P2PStatus.charged]: 'Өткізілді',
        // [P2PStatus.cancelled]: 'Жойылды',
        [P2PStatus.rejected]: 'Жойылды',
        // [P2PStatus.refunded]: 'Қайтарылды',
        // [P2PStatus.failed]: 'Сәтсіз',
        [P2PStatus.new]: 'Жаңа',
        [P2PStatus.any]: 'Кез келген'
    },
    sortBy: 'Бойынша сұрыптау',
    filtersPlaceholder: 'Аударма атрибуттары бойынша сүзгі',
    dataFields: {
        full: {
            accountID: 'Клиент сәйкестендіргіші',
            amount: 'Аударма сомасы',
            currency: 'Валюта',
            invoiceID: 'Тапсырыс нөмірі',
            reference: 'Карта жүйесінің жауабы',
            description: 'Бөлшектер аудармасы',
            fee: 'Ұсталған комиссия',
            language: 'Тілі',
            createdDate: 'Құрылған күні',
            dateTime: 'Жасалған күні',

            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',

            email: 'Клиенттің Email',
            ip: 'Клиенттің IP',
            name: 'Карта иесінің аты',
            phone: 'Клиенттің телефон нөмірі',

            secure: '3D Транзакция',
            code: 'Аударма мәртебесі',
            reason: 'Нәтижесі',

            senderCardMask: 'Жіберуші картасының маскасы',
            senderCardType: 'Жіберуші картасының түрі',
            // senderOwID: 'Жіберушінің ow id', 
            // senderCardID: 'Жіберушінің card id', 
            senderIssuer: 'Жіберушінің банк эмитенті',
            // senderTransferType: 'Жіберушінің аударма әдісі',

            receiverCardMask: 'Қабылдаушының карта маскасы',
            receiverCardType: 'Қабылдаушының карта түрі',
            // receiverCardID: 'Қабылдаушының card id',
            // receiverOwID: 'Қабылдаушының ow id',
            receiverIssuer: 'Қабылдаушының банк эмитенті',
            // receiverTransferType: 'Қабылдаушының аударма әдісі',

            data: 'Қосымша жолдар',

            //            shop: 'Дүкен атауы',
            //            amountBonus: 'Go Сомасы!бонустар',
            //            chargeDate: 'Растау күні',
            //            client: 'Клиент',
            //            installment_id: 'Бөліп төлеу мерзімі',            
            //            issuerBankCountry: 'Эмитент банкінің елі',
            //            merchant: 'Мерчантқа сілтеме',
            //            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк'
            //            payoutAmount: 'Қайтару сомасы',
            //            payoutDate: 'Қайтару күні',
            //            orgAmount: 'Бастапқы сомасы',
            //            template: '?',

        },
        short: {
            accountID: 'Клиент сәйкестендіргіші',
            amount: 'Аударма сомасы',
            currency: 'Валюта',
            invoiceID: 'Тапсырыс нөмірі',
            reference: 'РРН',
            description: 'Бөлшектер аудармасы',
            fee: 'Ұсталған комиссия',
            language: 'Тілі',
            createdDate: 'Құрылған күні',
            dateTime: 'Жасалған күні',

            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',

            email: 'Email',
            ip: 'Клиенттің IP',
            name: 'Карта иесінің аты',
            phone: 'Телефон',

            secure: '3D Транзакция',
            code: 'Статус',
            reason: 'Нәтижесі',

            senderCardMask: 'Жіберуші картасының маскасы',
            senderCardType: 'Жіберуші картасының түрі',
            senderIssuer: 'Жіберушінің банк эмитенті',
            // senderCardID: 'Жіберушінің card id', 
            // senderOwID: 'Жіберушінің ow id', 
            // senderTransferType: 'Жіберушінің аударма әдісі',

            receiverCardMask: 'Қабылдаушының карта маскасы',
            receiverCardType: 'Қабылдаушының карта түрі',
            // receiverCardID: 'Қабылдаушының card id',
            // receiverOwID: 'Қабылдаушының ow id',
            receiverIssuer: 'Қабылдаушының банк эмитенті',
            // receiverTransferType: 'Қабылдаушының аударма әдісі',

            data: 'Қосымша жолдар',

            //            chargeDate: 'Растау күні',
            //            client: 'Клиент',
            //            shop: 'Дүкен',
            //            installment_id: 'Бөліп төлеу мерзімі',
            //            amountBonus: 'Go Сомасы!бонустар',
            //            issuerBankCountry: 'Эмитент банкінің елі',
            //            merchant: 'Мерчантқа сілтеме',
            //            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк'
            //            payoutAmount: 'Қайтару сомасы',
            //            payoutDate: 'Қайтару күні',
            //            orgAmount: 'Бастапқы сомасы',
            //            template: 'Шаблон платежной страницы',

        },
        groups: {
            location: 'Орналасқан жері',
            paymentPage: 'Аударма беті'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        accountId: 'Клиент сәйкестендіргіші',
        amount: 'Төлем сомасы',
        amountBonus: 'Go Сомасы!бонустар',
        approvalCode: 'Авторизация коды',
        cardMask: 'Карта маскасы',
        senderEmail: 'Email',
        senderCardMask: 'Жіберуші картасының маскасы',
        receiverCardMask: 'Қабылдаушының карта маскасы',
        chargeback: 'chargeback',
        date: 'Күні бойынша',
        description: 'Бөлшектер төлем',
        email: 'Email',
        fee: 'Ұсталған комиссия',
        orderId: 'Тапсырыс нөмірі',
        invoiceIdAlt: 'Балама тапсырыс нөмірі',
        orgAmount: 'Бастапқы сомасы',
        payerName: 'Карта иесінің аты',
        payerPhone: 'Телефон',
        paymentType: 'Төлем әдісі',
        payoutAmount: 'Қайтару сомасы',
        payoutDate: 'Қайтару күні',
        postLink: 'Постлинк',
        purpose: 'Түсінік',
        qrReference: 'QR Reference',
        reference: 'РРН',
        resultMessage: 'Нәтижесі',
        status: 'Мәртебесі бойынша',
        type: 'Типі бойынша',
        user: 'Пайдаланушы',
        user_ip: 'Пайдаланушы IP',
        asc: {
            accountId: 'A → Z',
            amount: 'өсу бойынша',
            amountBonus: 'өсу бойынша',
            approvalCode: 'өсу бойынша',
            cardMask: 'өсу бойынша',
            senderEmail: 'A → Z',
            senderCardMask: 'өсу бойынша',
            receiverCardMask: 'өсу бойынша',
            chargeback: 'chargeback',
            date: 'басында ескілері',
            description: 'A → Z',
            email: 'A → Z',
            fee: 'өсу бойынша',
            orderId: 'өсу бойынша',
            invoiceIdAlt: 'өсу бойынша',
            orgAmount: 'өсу бойынша',
            payerName: 'A → Z',
            payerPhone: 'A → Z',
            paymentType: 'өсу бойынша',
            payoutAmount: 'өсу бойынша',
            payoutDate: 'өсу бойынша',
            postLink: 'өсу бойынша',
            purpose: 'A → Z',
            qrReference: 'A → Z',
            reference: 'өсу бойынша',
            resultMessage: 'A → Z',
            status: 'басында күтеді',
            type: 'A → Z',
            user: 'A → Z',
            user_ip: 'өсу бойынша'
        },
        desc: {
            date: 'басында жаңалары',
            postLink: 'басындағы сәтсіздері',
            status: 'алдымен қайтарылғандар',
            orderId: 'кему бойынша',
            accountId: 'Z ← A',
            amount: 'кему бойынша',
            amountBonus: 'кему бойынша',
            approvalCode: 'кему бойынша',
            cardMask: 'кему бойынша',
            senderEmail: 'Z ← A',
            senderCardMask: 'кему бойынша',
            receiverCardMask: 'кему бойынша',
            chargeback: 'кему бойынша',
            description: 'Z ← A',
            email: 'Z ← A',
            fee: 'кему бойынша',
            invoiceIdAlt: 'кему бойынша',
            orgAmount: 'кему бойынша',
            payerName: 'Z ← A',
            payerPhone: 'Z ← A',
            paymentType: 'кему бойынша',
            payoutAmount: 'кему бойынша',
            payoutDate: 'старые вначале',
            purpose: 'Z ← A',
            qrReference: 'Z ← A',
            reference: 'кему бойынша',
            resultMessage: 'Z ← A',
            type: 'Z ← A',
            user: 'Z ← A',
            user_ip: 'кему бойынша'
        }
    },
    installments: {
        full: {
            '3m': '3 ай',
            '6m': '6 ай',
            '9m': '9 ай',
            '12m': '12 ай'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secureValues: {
        true: 'Иә',
        false: 'Жоқ'
    },
    partialSum: {
        isRequired: 'Толтыруға міндетті сома',
        constraints: 'Аударма сомасынан аспайтын оң сан болуы тиіс'
    },
    operations: {
        fullCharge: {
            title: 'Толық есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Аударма бойынша толық есептен шығару қатесі `
        },
        partialCharge: {
            title: 'Ішінара есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Аударма бойынша ішінара есептен шығару қатесі `
        },
        cancel: {
            title: 'Болдырмау',
            itemError: paymentReference =>
                `#${paymentReference} Аударманы болдырмау қатесі`
        },
        fullRefund: {
            title: 'Толық қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Аударманы толық қайтару қатесі`
        },
        partialRefund: {
            title: 'Ішінара қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Аударманы ішінара қайтару қатесі `
        }
    },
    postLinkStatus: {
        all: 'Барлығы',
        ok: 'Сәтті',
        fail: 'Қате'
    },
    collapseSummary: range =>
        `${range instanceof Function ? range() : range || 'период'
        } кезең үшін статистика`,
    errors: {
        summaryLoadError: 'Статистиканы жүктеу қатесі',
        paymentsLoadError: 'Аудармаларды жүктеу қатесі'
    }
})

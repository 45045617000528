import res from './res'

import { P2PStatus } from '~/api'

res.add('en', {
    title: 'ePay transfer',
    ranges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter',
        customPeriod: 'custom period'
    },
    anyPaymentStatus: 'Any',
    paymentStatuses: {
        // [P2PStatus.auth]: 'Pending',
        [P2PStatus.charged]: 'Charged',
        // [P2PStatus.cancelled]: 'Cancelled',
        [P2PStatus.rejected]: 'Cancelled',
        // [P2PStatus.refunded]: 'Refunded',
        // [P2PStatus.failed]: 'Failed',
        [P2PStatus.new]: 'New',
        [P2PStatus.any]: 'Any'
    },
    sortBy: 'Sort by',
    filtersPlaceholder: 'Transfer attributes filter',
    dataFields: {
        full: {
            accountID: 'Customer identifier',
            amount: 'Transfer amount',
            currency: 'Currency',
            invoiceID: 'Order No.',
            reference: 'Retrieval reference number',
            description: 'Transfer details',
            fee: 'Fee',
            language: 'Language',
            createdDate: 'Created on',
            dateTime: 'Created on',

            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',

            email: 'Customer email',
            ip: 'Customer IP',
            name: 'Card owner name',
            phone: 'Customer phone',

            secure: 'Is 3D secure',
            code: 'Transfer status',
            reason: 'Result',

            senderCardMask: 'Sender card mask',
            senderCardType: 'Sender card type',
            senderIssuer: 'Sender issuer',
            // senderCardID: 'Sender card id', 
            // senderOwID: 'Sender ow id', 
            // senderTransferType: 'Sender Transfer method', 

            receiverCardMask: 'Receiver card mask',
            receiverCardType: 'Receiver card type',
            // receiverCardID: 'Receiver card id',
            // receiverOwID: 'Receiver ow id',
            receiverIssuer: 'Receiver issuer',
            // receiverTransferType: 'Receiver transfer type',

            data: 'Additional fields',

            //            amountBonus: 'Go!-bonuses amount',
            //            chargeDate: 'Charged on',
            //            client: 'Customer',
            //            installment_id: 'Installment',
            //            issuerBankCountry: 'Issuer country',
            //            shop: 'Shop name',
            //            merchant: 'Merchant reference',
            //            postLink: 'PostLink address',
            postLinkStatus: 'PostLink'
            //            payoutAmount: 'Refund amount',
            //            payoutDate: 'Refund date',
            //            template: 'Billing page template',
            //            orgAmount: 'Initial amount',
            //            transactionTypes: 'Transaction types',

        },
        short: {
            accountID: 'Customer ID',
            amount: 'Amount',
            currency: 'Currency',
            invoiceID: 'Order #',
            reference: 'RRN',
            description: 'Description',
            fee: 'Fee',
            language: 'Language',
            createdDate: 'Date',
            dateTime: 'Created On',

            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',

            email: 'Email',
            ip: 'Payer IP',
            name: 'Payer',
            phone: 'Phone',

            secure: 'Is 3D secure',
            code: 'Status',
            reason: 'Result',

            senderCardMask: 'Sender card mask',
            senderCardType: 'Sender card type',
            senderIssuer: 'Sender issuer',
            // senderCardID: 'Sender card id', 
            // senderOwID: 'Sender ow id', 
            // senderTransferType: 'Sender Transfer method',

            receiverCardMask: 'Receiver card mask',
            receiverCardType: 'Receiver card type',
            // receiverCardID: 'Receiver card id',
            // receiverOwID: 'Receiver ow id',
            receiverIssuer: 'Receiver issuer',
            // receiverTransferType: 'Receiver transfer type',

            data: 'Additional fields',

            //            chargeDate: 'Charged On',
            //            client: 'Customer',
            //            shop: 'Shop',
            //            installment_id: 'Installment',
            //            amountBonus: 'Go!bonus',
            //            issuerBankCountry: 'Issuer country',
            //            merchant: 'Merchant reference',
            //            postLink: 'PostLink address',
            postLinkStatus: 'PostLink'
            //            payoutAmount: 'Refund amount',
            //            payoutDate: 'Refunded On',
            //            template: 'Payment page template',
            //            orgAmount: 'Initial amount',
            //            transactionTypes: 'Transaction types',

        },
        groups: {
            location: 'Location',
            paymentPage: 'Transfer Page'
        },
        geolocation: 'Geolocation'
    },
    sortOptions: {
        accountId: 'Customer ID',
        amount: 'Amount',
        amountBonus: 'Go!bonus',
        approvalCode: 'Authorization code',
        cardMask: 'Card',
        senderEmail: 'Email',
        senderCardMask: 'Sender card mask',
        receiverCardMask: 'Receiver card mask',
        chargeback: 'chargeback',
        date: 'Date',
        description: 'Description',
        email: 'Email',
        fee: 'Fee',
        orderId: 'Order #',
        invoiceIdAlt: 'Alternative Order #',
        orgAmount: 'Initial amount',
        payerName: 'Payer',
        payerPhone: 'Phone',
        paymentType: 'Payment method',
        payoutAmount: 'Refund amount',
        payoutDate: 'Refunded On',
        postLink: 'PostLink',
        purpose: 'Purpose',
        qrReference: 'QR Reference',
        reference: 'RRN',
        resultMessage: 'Result',
        status: 'Status',
        type: 'Type',
        user: 'User',
        user_ip: 'User ip',
        asc: {
            accountId: 'A → Z',
            amount: 'asc',
            amountBonus: 'asc',
            approvalCode: 'asc',
            cardMask: 'asc',
            senderEmail: 'A → Z',
            senderCardMask: 'asc',
            receiverCardMask: 'asc',
            chargeback: 'asc',
            date: 'old first',
            description: 'A → Z',
            email: 'A → Z',
            fee: 'asc',
            orderId: 'asc',
            invoiceIdAlt: 'asc',
            orgAmount: 'asc',
            payerName: 'A → Z',
            payerPhone: 'A → Z',
            paymentType: 'asc',
            payoutAmount: 'asc',
            payoutDate: 'old first',
            postLink: 'successful first',
            purpose: 'A → Z',
            qrReference: 'A → Z',
            reference: 'A → Z',
            resultMessage: 'A → Z',
            status: 'pending first',
            type: 'A → Z',
            user: 'A → Z',
            user_ip: 'asc'
        },
        desc: {
            date: 'new first',
            postLink: 'failed first',
            status: 'refund first',
            orderId: 'desc',
            accountId: 'Z ← A',
            amount: 'desc',
            amountBonus: 'desc',
            approvalCode: 'desc',
            cardMask: 'desc',
            senderEmail: 'Z ← A',
            senderCardMask: 'desc',
            receiverCardMask: 'desc',
            chargeback: 'desc',
            description: 'Z ← A',
            email: 'Z ← A',
            fee: 'desc',
            invoiceIdAlt: 'desc',
            orgAmount: 'desc',
            payerName: 'Z ← A',
            payerPhone: 'Z ← A',
            paymentType: 'desc',
            payoutAmount: 'desc',
            payoutDate: 'old first',
            purpose: 'Z ← A',
            qrReference: 'Z ← A',
            reference: 'desc',
            resultMessage: 'Z ← A',
            type: 'Z ← A',
            user: 'Z ← A',
            user_ip: 'desc'
        }
    },
    installments: {
        full: {
            '3m': '3 months',
            '6m': '6 months',
            '9m': '9 months',
            '12m': '12 months'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secureValues: {
        true: 'Yes',
        false: 'No'
    },
    partialSum: {
        isRequired: 'Sum field is required',
        constraints: 'Sum must be a positive number not greater than the transfer sum'
    },
    operations: {
        fullCharge: {
            title: 'Full charge',
            itemError: paymentReference => `Error full charge on transfer #${paymentReference}`
        },
        partialCharge: {
            title: 'Partial charge',
            itemError: paymentReference => `Error partial charge on transfer #${paymentReference}`
        },
        cancel: {
            title: 'Cancel',
            itemError: paymentReference => `Error cancellation on transfer #${paymentReference}`
        },
        fullRefund: {
            title: 'Full refund',
            itemError: paymentReference => `Error full refund on transfer #${paymentReference}`
        },
        partialRefund: {
            title: 'Partial refund',
            itemError: paymentReference => `Error partial refund on transfer #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'All',
        ok: 'OK',
        fail: 'Failed'
    },
    collapseSummary: range => `Statistics for ${range instanceof Function ? range() : range || 'period'}`,
    errors: {
        summaryLoadError: 'Summary load error',
        paymentsLoadError: 'Transfers load error'
    }
})

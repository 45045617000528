import res from './res'

import { P2PStatus } from '~/api'

res.add('ru', {
    title: 'ePay переводы',
    ranges: {
        today: 'сегодня',
        yesterday: 'вчера',
        thisWeek: 'текущую неделю',
        priorWeek: 'прошлую неделю',
        thisMonth: 'текущий месяц',
        priorMonth: 'прошлый месяц',
        thisQuarter: 'текущий квартал',
        customPeriod: 'указанный период'
    },
    anyPaymentStatus: 'Любой',
    paymentStatuses: {
        // [P2PStatus.auth]: 'Ожидает',
        [P2PStatus.charged]: 'Проведен',
        // [P2PStatus.cancelled]: 'Отменен',
        [P2PStatus.rejected]: 'Отклонен',
        // [P2PStatus.refunded]: 'Возвращен',
        // [P2PStatus.failed]: 'Неудачный',
        [P2PStatus.new]: 'Новый',
        [P2PStatus.any]: 'Любой'
    },
    sortBy: 'Сортировать по',
    filtersPlaceholder: 'Фильтр по атрибутам переводов',
    dataFields: {
        full: {
            accountID: 'Идентификатор клиента',
            amount: 'Сумма перевода',
            currency: 'Валюта',
            invoiceID: 'Номер заказа',
            reference: 'Ответ от карточной системы',
            description: 'Детали перевода',
            fee: 'Удержанная комиссия',
            language: 'Язык',
            createdDate: 'Дата создания',
            dateTime: 'Дата создания',

            ipCity: 'Город',
            ipCountry: 'Страна',
            ipDistrict: 'Район',
            ipLatitude: 'Широта',
            ipLongitude: 'Долгота',
            ipRegion: 'Регион',

            email: 'Email клиента',
            ip: 'IP клиента',
            name: 'Имя владельца карты',
            phone: 'Номер телефона клиента',

            secure: 'Транзакция с 3D',
            code: 'Статус перевода',
            reason: 'Результат',

            senderCardMask: 'Маска карта отправителя',
            senderCardType: 'Тип карты отправителя',
            senderIssuer: 'Банк эмитент отправителя',
            // senderCardID: 'Card id отправителя', 
            // senderOwID: 'Ow id отправителя', 
            // senderTransferType: 'Метод перевода отправителя',

            receiverCardMask: 'Маска карта получателя',
            receiverCardType: 'Тип карты получаеля',
            // receiverCardID: 'Card id получателя',
            // receiverOwID: 'Ow Id получателя',
            receiverIssuer: 'Банк эмитент получателя',
            // receiverTransferType: 'Метод перевода получателя',

            data: 'Дополнительные поля',

            //            installment_id: 'Период рассрочки',
            //            amountBonus: 'Сумма Go!бонусов',
            //            chargeDate: 'Дата подтверждения',
            //            client: 'Клиент',
            //            shop: 'Название магазина',
            //            issuerBankCountry: 'Страна банка эмитента',
            //            merchant: 'Ссылка на мерчанта',
            //            postLink: 'Ссылка перенаправления',
            postLinkStatus: 'Постлинк'
            //            payoutAmount: 'Сумма возврата',
            //            payoutDate: 'Дата возврата',
            //            template: '?',
            //            transactionTypes: 'Виды Транзакции',
            //            orgAmount: 'Начальная сумма',

        },
        short: {
            accountID: 'ID клиента',
            amount: 'Сумма',
            currency: 'Валюта',
            invoiceID: '№ заказа',
            reference: 'РРН',
            description: 'Детали',
            fee: 'Комиссия',
            language: 'Язык',
            createdDate: 'Дата',
            dateTime: 'Дата создания',

            ipCity: 'Город',
            ipCountry: 'Страна',
            ipDistrict: 'Район',
            ipLatitude: 'Широта',
            ipLongitude: 'Долгота',
            ipRegion: 'Регион',

            email: 'Email',
            ip: 'IP клиента',
            name: 'Плательщик',
            phone: 'Телефон',

            secure: 'Транзакция с 3D',
            code: 'Статус',
            reason: 'Результат',

            senderCardMask: 'Маска карта отправителя',
            senderCardType: 'Тип карты отправителя',
            senderIssuer: 'Банк эмитент отправителя',
            // senderCardID: 'Card id отправителя', 
            // senderOwID: 'Ow id отправителя', 
            // senderTransferType: 'Метод перевода отправителя',

            receiverCardMask: 'Маска карта получателя',
            receiverCardType: 'Тип карты получаеля',
            // receiverCardID: 'Card id получателя',
            // receiverOwID: 'Ow Id получателя',
            receiverIssuer: 'Банк эмитент получателя',
            // receiverTransferType: 'Метод перевода получателя',

            data: 'Дополнительные поля',

            //            installment_id: 'Рассрочка',
            //            amountBonus: 'Go!бонус',
            //            chargeDate: 'Дата подтверждения',
            //            client: 'Клиент',
            //            shop: 'Магазин',
            //            issuerBankCountry: 'Страна банка эмитента',
            //            merchant: 'Ссылка на мерчанта',
            //            postLink: 'Ссылка перенаправления',
            postLinkStatus: 'Постлинк'
            //            payoutAmount: 'Возврат-сумма',
            //            payoutDate: 'Возврат-дата',
            //            template: 'Шаблон платежной страницы',
            //            orgAmount: 'Начальная сумма',
            //            transactionTypes: 'Виды транзакции',

        },
        groups: {
            location: 'Местоположение',
            paymentPage: 'Страница перевода'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        accountId: 'ID клиента',
        amount: 'Сумма',
        amountBonus: 'Сумма Go!бонусов',
        approvalCode: 'Код авторизации',
        cardMask: 'Номер карты',
        senderEmail: 'Email',
        senderCardMask: 'Маска карта отправителя',
        receiverCardMask: 'Маска карта получателя',
        chargeback: 'chargeback',
        date: 'Дате',
        description: 'Детали платежа',
        email: 'Email',
        fee: 'Комиссия',
        orderId: 'Номер заказа',
        invoiceIdAlt: 'Альтернативный № заказа',
        orgAmount: 'Начальная сумма',
        payerName: 'Плательщик',
        payerPhone: 'Телефон',
        paymentType: 'Метод оплаты',
        payoutAmount: 'Возврат-сумма',
        payoutDate: 'Возврат-дата',
        postLink: 'Постлинк',
        purpose: 'Комментарий',
        qrReference: 'QR Reference',
        reference: 'РРН',
        resultMessage: 'Результат',
        status: 'Статусу',
        type: 'Типу',
        user: 'Пользователь',
        user_ip: 'IP-пользователя',
        asc: {
            accountId: 'A → Z',
            amount: 'по возрастанию',
            amountBonus: 'по возрастанию',
            approvalCode: 'по возрастанию',
            cardMask: 'по возрастанию',
            senderEmail: 'A → Z',
            senderCardMask: 'по возрастанию',
            receiverCardMask: 'по возрастанию',
            chargeback: 'по возрастанию',
            date: 'старые вначале',
            description: 'A → Z',
            email: 'A → Z',
            fee: 'по возрастанию',
            orderId: 'по возрастанию',
            invoiceIdAlt: 'по возрастанию',
            orgAmount: 'по возрастанию',
            payerName: 'A → Z',
            payerPhone: 'A → Z',
            paymentType: 'по возрастанию',
            payoutAmount: 'по возрастанию',
            payoutDate: 'старые вначале',
            postLink: 'успешные вначале',
            purpose: 'A → Z',
            qrReference: 'A → Z',
            reference: 'по возрастанию',
            resultMessage: 'A → Z',
            type: 'A → Z',
            user: 'A → Z',
            user_ip: 'по возрастанию',
            status: 'вначале ожидают'
        },
        desc: {
            accountId: 'Z ← A',
            amount: 'по убыванию',
            amountBonus: 'по убыванию',
            approvalCode: 'по убыванию',
            cardMask: 'по убыванию',
            senderEmail: 'Z ← A',
            senderCardMask: 'по убыванию',
            receiverCardMask: 'по убыванию',
            chargeback: 'по убыванию',
            date: 'новые вначале',
            description: 'Z ← A',
            email: 'Z ← A',
            fee: 'по убыванию',
            orderId: 'по убыванию',
            invoiceIdAlt: 'по убыванию',
            orgAmount: 'по убыванию',
            payerName: 'Z ← A',
            payerPhone: 'Z ← A',
            paymentType: 'по убыванию',
            payoutAmount: 'по убыванию',
            payoutDate: 'старые вначале',
            postLink: 'неуспешные вначале',
            purpose: 'Z ← A',
            qrReference: 'Z ← A',
            reference: 'по убыванию',
            resultMessage: 'Z ← A',
            status: 'вначале возвраты',
            type: 'Z ← A',
            user: 'Z ← A',
            user_ip: 'по убыванию'
        }
    },
    installments: {
        full: {
            '3m': '3 месяца',
            '6m': '6 месяцев',
            '9m': '9 месяцев',
            '12m': '12 месяцев'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secureValues: {
        true: 'Да',
        false: 'Нет'
    },
    partialSum: {
        isRequired: 'Сумма обязательна к заполнению',
        constraints:
            'Сумма должна быть положительным числом не более суммы перевода'
    },
    operations: {
        fullCharge: {
            title: 'Полное списание',
            itemError: paymentReference =>
                `Ошибка полного списания по переводу #${paymentReference}`
        },
        partialCharge: {
            title: 'Частичное списание',
            itemError: paymentReference =>
                `Ошибка частичного списания по переводу #${paymentReference}`
        },
        cancel: {
            title: 'Отмена',
            itemError: paymentReference =>
                `Ошибка отмены перевода #${paymentReference}`
        },
        fullRefund: {
            title: 'Полный возврат',
            itemError: paymentReference =>
                `Ошибка полного возврата перевода #${paymentReference}`
        },
        partialRefund: {
            title: 'Частичный возврат',
            itemError: paymentReference =>
                `Ошибка частичного возврата перевода #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'Все',
        ok: 'Успешно',
        fail: 'Ошибка'
    },
    collapseSummary: range =>
        `Статистика за ${range instanceof Function ? range() : range || 'период'
        }`,
    errors: {
        summaryLoadError: 'Ошибка загрузки статистики',
        paymentsLoadError: 'Ошибка загрузки переводов'
    }
})

import { Collapse, Icon } from 'antd'
const { Panel } = Collapse
import React, { useState } from 'react'
import styles from './styles.css'
import res from './res'
import { Chargeback } from '~/api'

interface ChargebackProps {
    chargeback?: Chargeback
    children: React.ReactNode
}

const CollapseChargeback = ({ chargeback, children }: ChargebackProps) => {
    const [isCollapseOpened, setCollapseOpened] = useState(false)

    const callback = key => setCollapseOpened(!!key.length)

    return (
        <Collapse onChange={callback}
            bordered={false}
            expandIconPosition="right"
            className={styles.collapseChargeback}
            expandIcon={({ isActive }) => <Icon type="down" rotate={isActive ? -180 : 0} />}
        >
            <Panel header="Chargeback" key="1" extra={<span>{isCollapseOpened ? res().hide : res().show}</span>}>
                {children}
            </Panel>
        </Collapse>
    )
}

export default CollapseChargeback
import React from 'react'

import { observer } from 'mobx-react'

import { PaymentStatus } from '~/api'

import { RangePopover } from '~/components'

import { RangePopoverValue } from '~/components/range-popover'

import StatusSelect from '../../../components/status-select'

import { PaymentLinkStatus } from '~/api/payment-links'

import epayRes from '../../res'

import res from './res'

interface BaseFiltersProps {
    rangePresets: RangePopoverValue[]
    range: RangePopoverValue
    onRangeChange: (value: RangePopoverValue) => void
    status: PaymentStatus | PaymentLinkStatus
    onStatusChange: (value: PaymentStatus | PaymentLinkStatus) => void
    className?: string
    statusesOptions?: Array<{ key: string; title: () => string | string }>
    dateLabel?: string
}

export const StatusesTitlesRes = () => epayRes().paymentStatuses

export const StatusesOptions = [
    {
        key: PaymentStatus.any,
        title: () => epayRes().anyPaymentStatus.toLocaleLowerCase()
    },
    {
        key: PaymentStatus.auth,
        title: () => StatusesTitlesRes()[PaymentStatus.auth].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.charged,
        title: () =>
            StatusesTitlesRes()[PaymentStatus.charged].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.cancelled,
        title: () =>
            StatusesTitlesRes()[PaymentStatus.cancelled].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.refunded,
        title: () =>
            StatusesTitlesRes()[PaymentStatus.refunded].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.rejected,
        title: () => StatusesTitlesRes()[PaymentStatus.rejected].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.CANCEL_OLD,
        title: () =>
            StatusesTitlesRes()[PaymentStatus.CANCEL_OLD].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.REFUND_IN_PROCESSING_QR,
        title: () =>
            StatusesTitlesRes()[PaymentStatus.REFUND_IN_PROCESSING_QR].toLocaleLowerCase()
    },
    {
        key: PaymentStatus.VERIFIED,
        title: () =>
            StatusesTitlesRes()[PaymentStatus.VERIFIED].toLocaleLowerCase()
    }
]

export default observer((props: BaseFiltersProps) => {
    const onStatusChange = (value: string) => {
        props.onStatusChange(
            value === PaymentStatus.any ? undefined : (value as PaymentStatus)
        )
    }

    return (
        <div className={props.className}>
            <label htmlFor="range-popover">
                {props.dateLabel ? props.dateLabel : res().datePeriod}
                &nbsp;&nbsp;
            </label>
            <RangePopover
                id="range-popover"
                placement="bottomLeft"
                presets={props.rangePresets}
                value={props.range}
                onChange={props.onRangeChange}
            />
            ,&nbsp;&nbsp;
            <label htmlFor="status">{res().status}&nbsp;&nbsp;</label>
            <StatusSelect
                id="status"
                options={
                    props.statusesOptions
                        ? props.statusesOptions
                        : StatusesOptions
                }
                onChange={onStatusChange}
                value={props.status || 'any'}
            />
        </div>
    )
})

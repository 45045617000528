import {
    ListResponse,
    PaymentLinksListResponse,
    PaymentLinksRequest
} from '../contracts'

export enum AlertLanguages {
    russian = 'rus',
    kazakh = 'kaz',
    english = 'eng'
}

export enum PaymentLinkStatus {
    active = 'active',
    annulled = 'annulled',
    expired = 'expired',
    any = 'any',
    charged = 'charged'
}

export type PaymentLinkFilterableField =
    | 'id'
    | 'created_date'
    | 'shop_id'
    | 'invoice_id'
    | 'account_id'
    | 'payer_phone'
    | 'payer_email'
    | 'post_link'
    | 'amount'
    | 'card_mask'
    | 'client'
    | 'installment_id'
    | 'payout_date'
    | 'status'
    | 'result_code'

export type PaymentLinkSortableField =
    | 'id'
    | 'created_date'
    | 'status_id'
    | 'post_link'

export type PaymentLinkRequest = PaymentLinksRequest<
    PaymentLinkFilterableField,
    PaymentLinkSortableField
>

export enum PaymentLinkStatuss {
    ACTIVE = 'ACTIVE',
    CHARGED = 'CHARGED',
    ANNULLED = 'ANNULLED',
    EXPIRED = 'EXPIRED'
}

export interface PaymentLink {
    [x: string]: any
    id: string
    shop_id: string
    amount: number
    invoice_id: string
    language: string
    currency: string
    description: string
    account_id: string
    recipient_type: string
    recipient_contact: string
    recipient_contact_sms: string
    notifier_contact: string
    notifier_contact_sms: string
    expire_period: string
    postLink: string
    failurePostLink: string
    created_date: string
    expire_date: string
    status: PaymentLinkStatus
    updated_date: string
    invoice_url: string
    merchant_id: string
    terminal_id: string
}

export type PaymentLinkResponse = PaymentLinksListResponse<PaymentLink>
export interface PaymentLinksBaseInfo {
    id?: string
    merchantId?: string
    createdDate?: Date
    expireDate?: Date
    invoiceId?: string
    sum?: number
    amount: string
    email: string
    notifierContact?: string
    recipientContactSms: string
    language: string
    alertLanguage?: AlertLanguages
    installmentPriod?: string
    status?: PaymentLinkStatus
    statusId?: PaymentLinkStatus
}

export interface PaymentLinkDetails extends PaymentLinksBaseInfo {
    notificationEmail?: string
    notificationPhone?: string
    emailToSend?: string
    phoneToSend?: string
    description?: string
    link?: string
    selectedStoreName: string
    selectedStoreId: string
}

export interface PaymentLinkGrid extends PaymentLink {
    actions?: any
}

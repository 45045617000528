import { Moment } from 'moment'
import { ReactNode } from 'react'
import { FrequencyType } from '~/api/statements'
import locale from '~/utils/locale'

export default locale.create<{
    manualGenerationTitle: string
    scheduleTitle: (scheduleType: FrequencyType) => ReactNode
    periodTitle: string
    emailsTitle: string
    emailRequired: string
    invalidEmail: string
    formatTitle: string
    type: string
    transaction: string
    refund: string
    generateButton: string
    applyButton: string
    cancelButton: string
    active: string
    formatZip: string
    cols: {
        email: string
        control: string
        controlsTooltip: {
            edit: string
            add: string
            save: string
            cancel: string
            delete: string
        }
    }
}>()

import React from 'react'

import locale from '~/utils/locale'

export default locale.create<{
    noSelection: string
    multiStatusSelection: string
    fullAction: string
    partialAction: string

    chargeAction: string
    chargeFullForm: (paymentsCount: number, amounts: React.ReactNode, reason?: React.ReactNode) => React.ReactNode
    chargePartialTitle: string
    chargePartialDisable: string
    chargePartialOkButton: string

    cancelAction: string
    cancelForm: (paymentsCount: number, amounts: React.ReactNode, reason?: React.ReactNode) => React.ReactNode

    refundAction: string
    refundFullForm: (paymentsCount: number, amounts: React.ReactNode, reason: React.ReactNode) => React.ReactNode
    refundPartialTitle: string
    refundPartialDisable: string
    refundPartialOkButton: string

    noActionsForCancelled: React.ReactNode
    noActionsForRefunded: React.ReactNode
    confirmActions: {
        mainText: string
        simpleText: string
        simpleCancelText: string
        actions: {
            CHARGE: string
            PARTIALCHARGE: string
            CANCEL: string
            REFUND: string
            PARTIALREFUND: string
        },
        sumText: string,
        and: string
    },
    confirmAction: (actionType: any, amount: number, partialReundAmount?: number) => string,
    amountForm: {
        paymentAmount: string
        chargeAmount: string
        payoutAmount: string
    }
    reason: string
    reasonForReturn: string
    optional: string
    enterTheAmount: string
    okButton: string
    cancelButton: string
    partialCancelButton: string
    actionInProgress: string
}>()

import React from 'react'

import { action, observable, ObservableMap, remove, toJS } from 'mobx'

import { observer } from 'mobx-react'

import moment from 'moment'

import { CashByCodePayment, CashByCodeStatus } from '~/api'

import {
    Button,
    DatePicker,
    Form,
    IconButton,
    Input,
    Radio
} from '~/components'

import { FilterValue, FilterValues } from '~/components/data-filter'
import { CloseModalIcon } from '~/components/icon'
import { RangePopoverValue } from '~/components/range-popover'

import layoutStyles from '~/layouts/main/styles.css'

import globalRes from '~/res'

import constants from '../../constants'

import fieldsRes from '../../res'

import res from './res'

import { createFormItem } from '~/components/form-item-factory'
import styles from './styles.css'

import PeriodSelector from '~/components/date-selector/period-selector'
import MobileHeader from '~/components/modal/mobile-header'
import { ModalContainerContext } from '~/components/modal/modal-context'
import StatusSelect from '~/pages/payments/components/status-select'
import BrowserStore from '~/services/browser/browser-state'
import { CashByCodePaymentFiltersInterface } from '../../cash-by-code-store'
import { StatusesOptions } from '../base-filters/index'

interface FullFiltersFormProps {
    values: FilterValues<CashByCodePayment>
    dateRangeValue: RangePopoverValue
    dateRangePresets: RangePopoverValue[]
    onRangeChange?: (value: RangePopoverValue) => void
    onFiltersChange?: (
        filterParams: CashByCodePaymentFiltersInterface,
        overwrite?: boolean
    ) => void
    setStatusFilter?: (value: CashByCodeStatus) => void
    status?: CashByCodeStatus
    onStatusChange?: (value: CashByCodeStatus) => void
    visible: boolean
    onClose: (applyResult?: boolean) => any
    renderAsForm?: boolean
    onInit?: (applyFunc: () => void) => void
    modalContext?: ModalContainerContext
}

type PostLinkStatusOption = 'all' | 'ok' | 'fail'

const postLinkStatusOptions: PostLinkStatusOption[] = ['all', 'ok', 'fail']

const FormItem = createFormItem({
    labelCol: {
        xs: { span: 28 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 28 },
        sm: { span: 16 }
    }
})

@observer
export default class FullFiltersForm extends React.Component<
FullFiltersFormProps,
{}
> {
    constructor(props: FullFiltersFormProps) {
        super(props)

        this.state = {}

        this.initValues()
    }

    @action
    public componentDidMount() {
        this.prepareValues(this.props)
        if (this.props.onInit) {
            this.props.onInit(this.applyFilters)
        }
        if (this.props.modalContext) {
            this.props.modalContext.modalController.setTitle(
                <MobileHeader
                    onOk={() => this.applyFilters()}
                    title={res().title}
                    rightControl={
                        <a onClick={() => this.clearFilters()}>
                            ({res().clearButton})
                        </a>
                    }
                    onCancel={this.props.onClose}
                />
            )
        }
    }

    public componentWillUnmount() {
        if (this.props.onInit) {
            this.props.onInit(undefined)
        }
    }

    @action
    public componentWillReceiveProps(nextProps: FullFiltersFormProps) {
        this.prepareValues(nextProps)
    }

    @action
    public prepareValues(props: FullFiltersFormProps) {
        this.values = observable.map(toJS(props.values))
        this.dateRange = props.dateRangeValue

        const postLinkStatusValue =
            props.values.isPostLink && props.values.isPostLink.equals

        if (typeof postLinkStatusValue !== 'undefined') {
            this.postLinkStatusOptionKey = postLinkStatusValue ? 'ok' : 'fail'
        } else {
            this.postLinkStatusOptionKey = 'all'
        }
    }

    public render() {
        const { values, visible, onClose, renderAsForm } = this.props

        if (!values) {
            return <span></span>
        }
        if (renderAsForm) {
            return this.renderForm()
        }

        return (
            <React.Fragment>
                {!BrowserStore.isMobile && (
                    <div data-semantic-id="modal">
                        <div>
                            <div className={layoutStyles.contentHeader}>
                                <div className={layoutStyles.title}>
                                    {res().title}
                                </div>
                                <IconButton
                                    className={`${layoutStyles.closeButton}`}
                                    icon={CloseModalIcon}
                                    dataSemanticId="close-form"
                                    tooltipTitle={globalRes().close}
                                    onClick={onClose}
                                />
                            </div>
                            {/* <IconButton
                            className={`${layoutStyles.closeButton}`}
                            icon={CloseModalIcon}
                            onClick={onClose}
                        /> */}
                        </div>
                        <div className={layoutStyles.contentMain}>
                            {this.renderForm()}
                        </div>
                        <div className={layoutStyles.contentFooter}>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => this.applyFilters()}
                            >
                                {res().applyButton}
                            </Button>
                            <Button size="large" onClick={() => onClose()}>
                                {res().cancelButton}
                            </Button>
                        </div>
                    </div>
                )}
                {BrowserStore.isMobile && (
                    <React.Fragment>
                        <div className="fullScroll">{this.renderForm()}</div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    private renderForm() {
        const { values } = this
        const labels = fieldsRes().dataFields.short
        const placehoders = fieldsRes().dataFields.full

        return (
            <div>
                <Form
                    className={`${styles.fields} ${BrowserStore.isMobile ? styles.mobile : ''
                        } `}
                    onSubmit={this.handleFormSubmit}
                >
                    <div className={styles.section}>
                        {BrowserStore.isMobile && (
                            <React.Fragment>
                                <FormItem label={labels.status}>
                                    <StatusSelect
                                        id="status"
                                        options={StatusesOptions}
                                        onChange={this.setStatusFilter}
                                        value={
                                            this.statusFilter ||
                                            CashByCodeStatus.any
                                        }
                                    />
                                </FormItem>
                                <FormItem label={res().periodTitle}>
                                    <div>
                                        <PeriodSelector
                                            selectedValue={this.dateRange}
                                            values={this.props.dateRangePresets}
                                            onClose={this.setRangeFilter}
                                            renderLabel={true}
                                        ></PeriodSelector>
                                        {this.dateRange.key ===
                                            'customPeriod' &&
                                            this.dateRange.range[0] &&
                                            this.dateRange.range[1] && (
                                                <div>
                                                    (
                                                    {moment(
                                                        this.dateRange.range[0]
                                                    ).format('L')}{' '}
                                                    -{' '}
                                                    {moment(
                                                        this.dateRange.range[1]
                                                    ).format('L')}
                                                    )
                                                </div>
                                            )}
                                    </div>
                                </FormItem>
                            </React.Fragment>
                        )}

                        <FormItem label={labels.invoiceId}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'invoiceId',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.invoiceId}
                                value={
                                    values.has('invoiceId')
                                        ? values.get('invoiceId').endsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.client}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'client',
                                        'equals',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.client}
                                value={
                                    values.has('client')
                                        ? values.get('client').equals
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.terminalID}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'terminalID',
                                        'startsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.terminalID}
                                value={
                                    values.has('terminalID')
                                        ? values.get('terminalID').startsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.epayTerminalID}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'epayTerminalID',
                                        'equals',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.epayTerminalID}
                                value={
                                    values.has('epayTerminalID')
                                        ? values.get('epayTerminalID').equals
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.reference}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'reference',
                                        'equals',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.reference}
                                value={
                                    values.has('reference')
                                        ? values.get('reference').equals
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.isPostLink}>
                            <Radio.Group
                                buttonStyle="solid"
                                className={styles.radioGroupContainer}
                                value={this.postLinkStatusOptionKey}
                                onChange={e =>
                                    this.handlePostLinkStatusValueChange(
                                        e.target.value
                                    )
                                }
                            >
                                {postLinkStatusOptions.map(item => (
                                    <Radio.Button
                                        key={`postlink_option_${item}`}
                                        value={item}
                                    >
                                        {res().postLinkStatusOptions[item]}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </FormItem>
                    </div>
                    <div className={styles.section}>
                        <FormItem
                            label={labels.amount}
                            className={styles.currencyAmount}
                        >
                            <Input
                                value={
                                    values.has('amount')
                                        ? values.get('amount').greaterOrEqual
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'amount',
                                        'greaterOrEqual',
                                        e.target.value
                                    )
                                }
                                placeholder={globalRes().valueFrom}
                            />
                            <Input
                                value={
                                    values.has('amount')
                                        ? values.get('amount').lessOrEqual
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'amount',
                                        'lessOrEqual',
                                        e.target.value
                                    )
                                }
                                placeholder={globalRes().valueTo}
                            />
                        </FormItem>

                        <FormItem label={labels.cardMask}>
                            <Input
                                value={
                                    values.has('cardMask')
                                        ? values.get('cardMask').startsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'cardMask',
                                        'startsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={res().cardsNumberStartsWith}
                            />
                            <Input
                                value={
                                    values.has('cardMask')
                                        ? values.get('cardMask').endsWith
                                        : ''
                                }
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'cardMask',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={res().cardsNumberEndsWith}
                            />
                        </FormItem>

                        <FormItem label={labels.destinationPhone}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'destinationPhone',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.destinationPhone}
                                value={
                                    values.has('destinationPhone')
                                        ? values.get('destinationPhone').endsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.sourcePhone}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'sourcePhone',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.sourcePhone}
                                value={
                                    values.has('sourcePhone')
                                        ? values.get('sourcePhone').endsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.receiverPhone}>
                            <Input
                                onChange={e =>
                                    this.handleFieldValueChange(
                                        'receiverPhone',
                                        'endsWith',
                                        e.target.value
                                    )
                                }
                                placeholder={placehoders.receiverPhone}
                                value={
                                    values.has('receiverPhone')
                                        ? values.get('receiverPhone').endsWith
                                        : ''
                                }
                            />
                        </FormItem>

                        <FormItem label={labels.authDate}>
                            <DatePicker
                                value={
                                    values.has('authDate') &&
                                        values.get('authDate').greaterOrEqual
                                        ? moment(
                                            values.get('authDate')
                                                .greaterOrEqual
                                        )
                                        : null
                                }
                                onChange={date =>
                                    this.setFieldValue(
                                        'authDate',
                                        'greaterOrEqual',
                                        date ? date.startOf('day') : date
                                    )
                                }
                                placeholder={globalRes().valueFrom}
                            />
                            <DatePicker
                                value={
                                    values.has('authDate') &&
                                        values.get('authDate').lessOrEqual
                                        ? moment(
                                            values.get('authDate')
                                                .lessOrEqual
                                        )
                                        : null
                                }
                                onChange={date =>
                                    this.setFieldValue(
                                        'authDate',
                                        'lessOrEqual',
                                        date ? date.endOf('day') : date
                                    )
                                }
                                placeholder={globalRes().valueTo}
                            />
                        </FormItem>

                        <FormItem label={labels.postingDate}>
                            <DatePicker
                                value={
                                    values.has('postingDate') &&
                                        values.get('postingDate').greaterOrEqual
                                        ? moment(
                                            values.get('postingDate')
                                                .greaterOrEqual
                                        )
                                        : null
                                }
                                onChange={date =>
                                    this.setFieldValue(
                                        'postingDate',
                                        'greaterOrEqual',
                                        date ? date.startOf('day') : date
                                    )
                                }
                                placeholder={globalRes().valueFrom}
                            />
                            <DatePicker
                                value={
                                    values.has('postingDate') &&
                                        values.get('postingDate').lessOrEqual
                                        ? moment(
                                            values.get('postingDate')
                                                .lessOrEqual
                                        )
                                        : null
                                }
                                onChange={date =>
                                    this.setFieldValue(
                                        'postingDate',
                                        'lessOrEqual',
                                        date ? date.endOf('day') : date
                                    )
                                }
                                placeholder={globalRes().valueTo}
                            />
                        </FormItem>

                    </div>
                </Form>
            </div>
        )
    }

    private handleFormSubmit(e) {
        e.preventDefault()
    }

    @action.bound
    private setStatusFilter(value: CashByCodeStatus) {
        this.statusFilter = value !== CashByCodeStatus.any ? value : undefined
    }

    @action.bound
    private setRangeFilter(value: RangePopoverValue) {
        if (value) {
            this.dateRange = value
        }
    }

    @action.bound
    private applyFilters() {
        this.props.onFiltersChange(
            {
                values: this.values,
                dateRange: this.dateRange,
                postLinkStatusOptionKey: this.postLinkStatusOptionKey
            },
            true
        )
        if (this.props.setStatusFilter) {
            this.props.setStatusFilter(
                this.statusFilter !== CashByCodeStatus.any
                    ? this.statusFilter
                    : undefined
            )
        }
        if (this.props.onRangeChange && this.dateRange) {
            this.props.onRangeChange(this.dateRange)
        }

        this.props.onClose()
    }

    @action.bound
    private clearFilters() {
        this.initValues()
        this.statusFilter = CashByCodeStatus.any
    }

    private handleFieldValueChange(
        field: keyof CashByCodePayment,
        operator: keyof FilterValue<CashByCodePayment>,
        value: string
    ) {
        const option = constants.filterOptions
            .get()
            .find(item => item.field === field)
        const type = option && option.type
        let parsedValue = value.trim()

        if (!parsedValue.length) {
            this.removeFieldValue(field, operator)
        } else {
            if (type) {
                if (!type.isValid(value)) {
                    return
                }
                parsedValue = type.parse(value)
            }
            this.setFieldValue(field, operator, parsedValue)
        }
    }

    @action.bound
    private handlePostLinkStatusValueChange(value: PostLinkStatusOption) {
        this.postLinkStatusOptionKey = value
    }

    @action
    private initValues() {
        this.values = observable.map()
        this.statusFilter = this.props.status
        this.dateRange = this.props.dateRangeValue
    }

    @action
    private setFieldValue(
        field: keyof CashByCodePayment,
        operator: keyof FilterValue<CashByCodePayment>,
        value: any
    ) {
        const { values } = this
        const filterData = values.get(field)

        values.set(field, {
            ...filterData,
            [operator]: value
        })
    }

    @action
    private removeFieldValue(
        field: keyof CashByCodePayment,
        operator: keyof FilterValue<CashByCodePayment>
    ) {
        const { values } = this
        const filterData = values.get(field)

        if (filterData) {
            remove(filterData, operator)

            if (!Object.keys(filterData).length) {
                values.delete(field)
            }
        }
    }

    @observable
    private values: ObservableMap<
        keyof CashByCodePayment,
        { [key in keyof FilterValue<CashByCodePayment>]: any }
    >

    @observable
    private statusFilter: CashByCodeStatus

    @observable
    private dateRange: RangePopoverValue

    @observable
    private postLinkStatusOptionKey: PostLinkStatusOption
}

import { observer } from 'mobx-react'
import { Component, useState } from 'react'
import React from 'react'

import { CheckBoxWithLabelFor } from '../../../../components/checkbox/checkbox-label-for'
import { Tooltip } from 'antd'
import {
    Button, DatePicker,
    Form,
    IconButton,
    Input,
    List,
    Radio,
    RangePopover,
    Spin,
    Switch,
    Table
} from '~/components'

import { FormComponentProps } from 'antd/lib/form'

const Group = Radio.Group
const { TextArea } = Input

import { action, toJS } from 'mobx'
import moment from 'moment'
import {
    ContractStatementSchedule,
    FrequencyType,
    ManualStatement
} from '~/api/statements'
import PeriodSelector from '~/components/date-selector/period-selector'
import { createFormItem } from '~/components/form-item-factory'
import {
    ArrowLeftIcon,
    CancelIcon,
    CheckIcon,
    CloseModalIcon,
    PlusIcon,
    SaveIcon
} from '~/components/icon'
import { ModalContainerContext } from '~/components/modal/modal-context'
import { RangePopoverValue } from '~/components/range-popover'
import layoutStyles from '~/layouts/main/styles.css'
import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'
import { StatementFormats } from '../../constants'
import statesmentRes from '../../res'
import StatementsTransferStore from '../../statements-transfer-store'
import res from './res'
import styles from './styles.css'

import { BinIcon, FormIcon } from '~/components/icon'

import isNil from 'lodash/isNil'
import pullAt from 'lodash/pullAt'
import remove from 'lodash/remove'

import { email, required, Validation } from '~/utils/validation'

interface GenerationFormProps {
    title?: React.ReactNode
    store: StatementsTransferStore
    frequency?: FrequencyType
    modalContext?: ModalContainerContext
}

interface GenerationFormState {
    statementSchedule?: ContractStatementSchedule
    manualStatement?: ManualStatement
    isEmailInEdit?: boolean
}

const FormItem = createFormItem({
    labelCol: {
        xs: { span: 28 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 28 },
        sm: { span: 16 }
    }
})

@observer
export default class GenerationForm extends Component<GenerationFormProps,
GenerationFormState> {
    constructor(props: GenerationFormProps) {
        super(props)
        this.state = {
            statementSchedule: props.frequency
                ? {
                    format: props.store.statement[props.frequency].format,
                    emails: toJS(
                        props.store.statement[props.frequency].emails
                    ),
                    enable: props.store.statement[props.frequency].enable,
                    schedulerId:
                        props.store.statement[props.frequency].schedulerId
                }
                : null,
            manualStatement: !props.frequency
                ? {
                    emails: [],
                    range: this.props.store.rangePresets[0],
                    shopID: this.props.store.statement.shopId,
                    format: StatementFormats.csv
                }
                : null
        }
        this.onApply = this.onApply.bind(this)
    }

    public cols = [
        {
            key: 'email',
            cellClassName: styles.emailCell
        },
        {
            key: 'control',
            cellClassName: styles.controlCell
        }
    ]

    public tableCols = () =>
        this.cols.map(col => {
            return {
                title: res().cols[col.key],
                ...col
            }
        })

    @action
    public componentDidMount() {
        if (this.props.modalContext) {
            const { title, store, frequency } = this.props
            const onClose = store.toggleStatementConfigForm

            this.props.modalContext.modalController.setTitle(
                <div className={styles.mobileHeader}>
                    <div className={layoutStyles.title}>
                        {title ||
                            (frequency
                                ? res().scheduleTitle(frequency)
                                : res().manualGenerationTitle)}
                    </div>
                    <IconButton
                        className={`${styles.closeButton}`}
                        icon={CloseModalIcon}
                        onClick={onClose}
                        id="close-statement-config-icon"
                        tooltipTitle={globalRes().close}
                    />
                </div>
            )
        }
    }

    public render() {
        const { title, store, frequency } = this.props
        const onClose = store.toggleStatementConfigForm
        const { manualStatement } = this.state

        return (
            <>
                <Spin spinning={store.statementUpdating}>
                    {!BrowserStore.isMobile && (
                        <div data-semantic-id="modal">
                            {' '}
                            <div>
                                <div className={layoutStyles.contentHeader}>
                                    <div className={layoutStyles.title}>
                                        {title ||
                                            (frequency
                                                ? res().scheduleTitle(frequency)
                                                : res().manualGenerationTitle)}
                                    </div>
                                    <IconButton
                                        className={`${layoutStyles.closeButton} ${styles.closeButton}`}
                                        icon={CloseModalIcon}
                                        onClick={onClose}
                                        id="close-statement-config-icon"
                                        tooltipTitle={globalRes().close}
                                    />
                                </div>
                            </div>
                            <div className={styles.contentMain}>
                                {this.renderForm()}
                            </div>
                            <div
                                className={`${layoutStyles.contentFooter} ${styles.footer}`}
                            >
                                <Button
                                    type="primary"
                                    disabled={this.state.isEmailInEdit}
                                    size="large"
                                    onClick={this.onApply}
                                >
                                    {manualStatement
                                        ? res().generateButton
                                        : res().applyButton}
                                </Button>
                                <Button size="large" onClick={() => onClose()}>
                                    {res().cancelButton}
                                </Button>
                            </div>
                        </div>
                    )}

                    {BrowserStore.isMobile && (
                        <>
                            <div className={`${styles.mobileFormContainer}`}>
                                {statesmentRes().sortBy}
                                {this.renderForm()}
                                <div className={styles.mobileFooter}>
                                    {/* <Button shape="circle" onClick={() => onClose()} ><ArrowLeftIcon /></Button> */}
                                    <Button
                                        type="primary"
                                        onClick={this.onApply}
                                    >
                                        {manualStatement
                                            ? res().generateButton
                                            : res().applyButton}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Spin>
            </>
        )
    }

    private renderForm() {
        const { store } = this.props
        const { statementSchedule, manualStatement } = this.state

        const emails = statementSchedule
            ? statementSchedule.emails || []
            : manualStatement.emails || []
        const format = statementSchedule
            ? statementSchedule.format
            : manualStatement.format

        const className = BrowserStore.isMobile ? styles.mobileFormItem : ''
        const validators: Array<Validation<string>> = [
            required(() => res().emailRequired),
            email(() => res().invalidEmail)
        ]
        return (
            <Form className={styles.form}>
                {statementSchedule && (
                    <FormItem label={res().active} className={className}>
                        <Switch
                            checked={
                                statementSchedule && statementSchedule.enable
                            }
                            onChange={isEnable => this.setActivation(isEnable)}
                        />
                    </FormItem>
                )}
                {manualStatement && (
                    <FormItem
                        label={res().dateTitle}
                        className={`${styles.period} ${className}`}
                    >
                        {BrowserStore.isMobile && (
                            <React.Fragment>
                                <DatePicker
                                    defaultValue={moment('2024-01-01', 'YYYY-mm-dd')}
                                    disabledDate={(current) => current && current < moment('2023-01-01')}
                                    onChange={(date) => {
                                        this.setRange({
                                            range: [moment(date).startOf('day'), moment(date).endOf('day')]
                                        })
                                    }}
                                />
                            </React.Fragment>
                        )}
                        {!BrowserStore.isMobile && (
                            <React.Fragment>
                                <DatePicker
                                    defaultValue={moment().subtract(1, 'days')}
                                    disabledDate={(current) => current && (current < moment('2023-01-01') || current >= moment().subtract(1, 'day'))}
                                    onChange={(date) => {
                                        this.setRange({
                                            range: [moment(date).startOf('day'), moment(date).endOf('day')]
                                        })
                                    }}
                                />


                                {/*<RangePopover*/}
                                {/*    id="period"*/}
                                {/*    placement="bottomLeft"*/}
                                {/*    presets={store.rangePresets}*/}
                                {/*    value={manualStatement.range}*/}
                                {/*    onChange={this.setRange}*/}
                                {/*/>*/}
                                {/*&nbsp;*/}
                                {/*{manualStatement.range.key && (*/}
                                {/*    <span*/}
                                {/*        className={`${styles.range} ${className}`}*/}
                                {/*    >*/}
                                {/*        (*/}
                                {/*        {moment(*/}
                                {/*            manualStatement.range.range[0]*/}
                                {/*        ).format('L')}{' '}*/}
                                {/*        -{' '}*/}
                                {/*        {moment(*/}
                                {/*            manualStatement.range.range[1]*/}
                                {/*        ).format('L')}*/}
                                {/*        )*/}
                                {/*    </span>*/}
                                {/*)}*/}


                            </React.Fragment>
                        )}
                    </FormItem>
                )}
                <FormItem
                    label={res().formatTitle}
                    className={`${styles.formatButtons} ${className}`}
                >
                    <Group
                        buttonStyle="solid"
                        id="formats"
                        value={format}
                        onChange={e => this.setFormat(e.target.value)}
                    >
                        {store.statementFormats.map(item => {
                            return (
                                <Radio.Button key={`format_${item}`} value={item}>
                                    {item}
                                </Radio.Button>
                            )
                        }
                        )}
                    </Group>
                    <Tooltip title={res().formatZip}>
                        <CheckBoxWithLabelFor className={styles.checkbox} onChange={e => e.target.checked ? this.setFileFormat('zip') : this.setFileFormat('default')}>
                            ZIP
                        </CheckBoxWithLabelFor>
                    </Tooltip>

                </FormItem>
                {this.props.frequency && (
                    <FormItem label={res().emailsTitle} className={className}>
                        <EditableValueGroup
                            values={emails}
                            validators={validators}
                            onGroupChange={this.applyChangedEmails}
                            onEditToggled={this.onEmailEditToggled}
                        ></EditableValueGroup>
                    </FormItem>
                )}
            </Form>
        )
    }

    private applyChangedEmails = (values: string[]) => {
        if (this.props.frequency) {
            const statementSchedule = toJS(this.state.statementSchedule)
            statementSchedule.emails = values
            this.setState({ statementSchedule })
        } else {
            const manualStatement = toJS(this.state.manualStatement)
            manualStatement.emails = values
            this.setState({ manualStatement })
        }
    }

    private onEmailEditToggled = (isEdit?: boolean) => {
        this.setState({ isEmailInEdit: isEdit })
    }

    private onApply() {

        if (this.state.statementSchedule) {
            this.props.store.applyScheduleStatementChanges(
                this.state.statementSchedule,
                this.props.frequency
            )
        } else {
            this.props.store.generateStatementApply(this.state.manualStatement)
        }
    }

    @action.bound
    private setFormat(value: string) {
        if (this.state.statementSchedule) {
            this.setState({
                statementSchedule: {
                    ...this.state.statementSchedule,
                    format: value
                }
            })
        }

        if (this.state.manualStatement) {
            this.setState({
                manualStatement: {
                    ...this.state.manualStatement,
                    format: value
                }
            })
        }
    }

    @action.bound
    private setActivation(value: boolean) {
        this.setState({
            statementSchedule: {
                ...this.state.statementSchedule,
                enable: value
            }
        })
    }

    @action.bound
    private setRange(value: RangePopoverValue) {
        this.setState({
            manualStatement: { ...this.state.manualStatement, range: value }
        })
    }

    @action.bound
    private setFileFormat(value: string) {
        if (this.state.statementSchedule) {
            this.setState({
                statementSchedule: {
                    ...this.state.statementSchedule,
                    fileFormat: value
                }
            })
        }

        if (this.state.manualStatement) {
            this.setState({
                manualStatement: {
                    ...this.state.manualStatement,
                    fileFormat: value
                }
            })
        }
    }
}

export interface EditableValueInterface<T> {
    value: T
    onChange?: (value: T, id: number) => void
    onDelete?: (id: number) => void
    onError?: (id: number, message?: string) => void
    onEditToggled?: (id: number, isEdit: boolean) => void
    validators?: Array<Validation<T>>
    itemId: number
}

export function EditableValue<T>(props: EditableValueInterface<T>) {
    const [value, setValue] = useState<T>(props.value)
    const [isEdit, setEdit] = useState(props.itemId === -1)
    const [errMessage, setMessage] = useState('')
    const [isError, setError] = useState(false)
    const onEdit = (isEditOn: boolean) => {
        if (props.onEditToggled) {
            props.onEditToggled(props.itemId, isEditOn)
        }
        if (isEditOn) {
            validateInput(props.value)
        }
        setEdit(isEditOn)
    }
    const clearError = () => {
        setMessage('')
        setError(false)
        if (props.onError) {
            props.onError(props.itemId, undefined)
        }
    }
    const cancelEdit = () => {
        setValue(props.value)
        if (props.itemId !== -1) {
            onEdit(false)
            if (props.onEditToggled) {
                props.onEditToggled(props.itemId, false)
            }
        }
        clearError()
    }
    const validateInput = (inputValue: T) => {
        if (props.validators) {
            let isErr = false
            props.validators.forEach(validator => {
                if (!validator.rule(inputValue)) {
                    setMessage(validator.message)
                    isErr = true
                }
            })
            setError(isErr)
            if (isError && props.onError) {
                props.onError(props.itemId, errMessage)
            }
        }
    }
    const setValueToState = (event: any) => {
        const inputValue = event.target.value
        if (props.validators) {
            validateInput(inputValue)
        }
        setValue(inputValue)
    }
    const applyValue = () => {
        if (!isError) {
            props.onChange(value, props.itemId)
            if (props.itemId === -1) {
                setValue(undefined)
            } else {
                onEdit(false)
            }
            if (props.onEditToggled) {
                props.onEditToggled(props.itemId, false)
            }
        }
    }
    const commitChanges = (event: React.KeyboardEvent) => {
        if (event.charCode === 13) {
            applyValue()
        }
    }
    const Frag = React.Fragment
    const someValue: any = value
    const editControl = (
        <Frag>
            <Input
                value={someValue}
                className={`${isError ? 'has-error' : ''}`}
                onChange={setValueToState}
                onKeyPress={commitChanges}
                data-semantic-id="statement_config_form_new_recipient"
            />
            {isError && <div className={styles.error}>{errMessage}</div>}
        </Frag>
    )
    return (
        <div className={styles.valueEditControl}>
            <div>
                {isEdit && editControl}
                {!isEdit && <span>{props.value}</span>}
            </div>
            <div>
                <span className={styles.actions}>
                    {isEdit && (
                        <React.Fragment>
                            <IconButton
                                icon={props.itemId === -1 ? PlusIcon : SaveIcon}
                                tooltipTitle={
                                    props.itemId === -1
                                        ? res().cols.controlsTooltip.add
                                        : res().cols.controlsTooltip.save
                                }
                                dataSemanticId="save-icon"
                                id="open-statement-settings-icon"
                                onClick={applyValue}
                                disabled={isError}
                            />
                            <IconButton
                                icon={CancelIcon}
                                key="button_cancel"
                                dataSemanticId="cancel-icon"
                                onClick={cancelEdit}
                                tooltipTitle={res().cols.controlsTooltip.cancel}
                            />
                        </React.Fragment>
                    )}
                    {!isEdit && (
                        <React.Fragment>
                            <IconButton
                                icon={FormIcon}
                                tooltipTitle={res().cols.controlsTooltip.edit}
                                dataSemanticId="edit-icon"
                                id="open-statement-settings-icon"
                                onClick={() => onEdit(true)}
                            />
                            <IconButton
                                icon={BinIcon}
                                key="button_cancel"
                                dataSemanticId="delete-icon"
                                onClick={() => props.onDelete(props.itemId)}
                                tooltipTitle={res().cols.controlsTooltip.delete}
                            />
                        </React.Fragment>
                    )}
                </span>
            </div>
        </div>
    )
}

export interface EditableValueGroupInterface<T> {
    values?: T[]
    onGroupChange?: (values: T[]) => void
    onEditToggled?: (isEdit: boolean) => void
    onValidationError?: (messages: string[]) => void
    validators?: Array<Validation<T>>
}

export interface EditItemInterface {
    itemIndex: number
    errorMessages?: string[]
    isEdit?: boolean
}

export function EditableValueGroup<T>(props: EditableValueGroupInterface<T>) {
    const [editItems, setEditItems] = useState<EditItemInterface[]>([])
    const onChangeToggle = (id: number) => {
        if (id !== -1) {
            const eItems = [...editItems]
            const isItemOnEdit = eItems.some(itm => itm.itemIndex === id)
            if (isItemOnEdit) {
                remove(eItems, itm => itm.itemIndex === id)
            } else {
                eItems.push({ isEdit: true, itemIndex: id })
            }
            setEditItems(eItems)
            if (props.onEditToggled) {
                props.onEditToggled(eItems.length > 0)
            }
        }
    }
    const onChange = (value?: T, id?: number) => {
        if (!isNil(id)) {
            if (value) {
                onChangeToggle(id)
            }
            if (props.onGroupChange) {
                const changedGroup = [...props.values]
                if (changedGroup[id]) {
                    if (value) {
                        changedGroup[id] = value
                    } else {
                        pullAt(changedGroup, id)
                    }
                } else {
                    if (value) {
                        changedGroup.push(value)
                    }
                }
                props.onGroupChange(changedGroup)
            }
        }
    }
    const enableAdding = true
    const items = props.values.map((itm, index) => (
        <EditableValue<T>
            value={itm}
            key={index}
            itemId={index}
            validators={props.validators}
            onChange={onChange}
            onEditToggled={onChangeToggle}
            onDelete={itmIndex => onChange(undefined, itmIndex)}
        ></EditableValue>
    ))
    return (
        <div>
            {enableAdding && (
                <EditableValue<T>
                    value={undefined}
                    itemId={-1}
                    validators={props.validators}
                    onChange={onChange}
                ></EditableValue>
            )}
            {items}
        </div>
    )
}
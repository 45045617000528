import React from 'react'

const SvgCardId = props => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 3.4H4a.6.6 0 00-.6.6v16a.6.6 0 00.6.6h16a.6.6 0 00.6-.6V4a.6.6 0 00-.6-.6zM4 2a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V4a2 2 0 00-2-2H4z"
            fill="#AB3EFF"
        />
        <path
            d="M6 17V8h2.215v9H6zM10.116 17V8h3.214c1.43 0 2.57.403 3.404 1.196C17.57 9.989 18 11.084 18 12.48c0 1.41-.43 2.518-1.266 3.323C15.9 16.61 14.76 17 13.33 17h-3.214zm2.214-1.888h.912c.771 0 1.379-.227 1.81-.68.43-.453.657-1.095.657-1.95 0-.832-.227-1.474-.658-1.927-.443-.44-1.05-.667-1.81-.667h-.91v5.224z"
            fill="#AB3EFF"
        />
    </svg>
)

export default SvgCardId

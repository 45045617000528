import React from 'react'

const SvgPaymentCard = props => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M5.333 9.5a.833.833 0 01.834-.834H9.5a.833.833 0 110 1.667H6.167a.833.833 0 01-.834-.834z"
            fill="#1C9771"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.333 4.5A3.333 3.333 0 002 7.833v8.334A3.333 3.333 0 005.333 19.5h13.334A3.333 3.333 0 0022 16.167V7.833A3.333 3.333 0 0018.667 4.5H5.333zm13.334 1.667H5.333a1.667 1.667 0 00-1.666 1.666v5.834h16.666V7.833a1.667 1.667 0 00-1.666-1.666zm1.666 9.166H3.667v.834a1.667 1.667 0 001.666 1.666h13.334a1.667 1.667 0 001.666-1.666v-.834z"
            fill="#1C9771"
        />
    </svg>
)

export default SvgPaymentCard

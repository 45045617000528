import React from 'react'

const SvgCardVerification = props => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M5.022 6.533a.756.756 0 01.756-.756H8.8a.756.756 0 010 1.511H5.778a.756.756 0 01-.756-.755z"
            fill="#00C2FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.022 2A3.022 3.022 0 002 5.022v7.556A3.022 3.022 0 005.022 15.6h12.09a3.022 3.022 0 003.021-3.022V5.022A3.022 3.022 0 0017.111 2H5.022zm12.09 1.511H5.021a1.511 1.511 0 00-1.51 1.511v5.29h15.11v-5.29a1.511 1.511 0 00-1.51-1.51zm1.51 8.311H3.512v.756a1.511 1.511 0 001.51 1.51h12.09a1.511 1.511 0 001.51-1.51v-.756z"
            fill="#00C2FF"
        />
        <path
            d="M21.933 13.967a7.967 7.967 0 11-15.933 0 7.967 7.967 0 0115.933 0z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.967 20.195a6.229 6.229 0 100-12.457 6.229 6.229 0 000 12.457zm0 1.738a7.967 7.967 0 100-15.933 7.967 7.967 0 000 15.933z"
            fill="#00C2FF"
        />
        <path
            d="M9.096 13.96c0-.52.167-.954.503-1.3.335-.346.767-.52 1.294-.52.24 0 .47.045.7.13.225.083.421.207.584.37l-.388.652a1.56 1.56 0 00-.407-.257 1.102 1.102 0 00-.45-.103c-.322 0-.561.098-.724.296a1.084 1.084 0 00-.245.712c0 .287.082.539.25.747.167.212.407.316.718.316.326 0 .628-.143.91-.435l.432.633a1.824 1.824 0 01-1.38.593c-.546 0-.983-.168-1.308-.514-.326-.346-.49-.786-.49-1.32zM13.524 15.735L12.31 12.2h.91l.604 2.012c.02.07.039.148.063.242.019.099.038.173.053.232l.014.094h.01a4.19 4.19 0 01.129-.568l.613-2.012h.896l-1.207 3.535h-.873z"
            fill="#00C2FF"
        />
        <path
            d="M16.758 15.735L15.545 12.2h.91l.605 2.012c.019.07.038.148.062.242.019.099.038.173.053.232l.014.094h.01a4.19 4.19 0 01.129-.568l.613-2.012h.897l-1.208 3.535h-.872z"
            fill="#00C2FF"
        />
    </svg>
)

export default SvgCardVerification

import { ReactNode } from 'react'
import locale from '~/utils/locale'

export default locale.create<{
    dataFields: {
        titles: {
            createdDate: string
            endDate: string
            order: string
            sum: string
            language: string
            installment: string
            invoice_alt: string
            status: string
            merchantId: string
            description: string
            emailToSend: string
            phoneToSend: string
            notificationEmail: string
            notificationPhone: string
        }
    }
    sortOptions: {
        createdDate: string
        expirationDate: string
        order: string
        amount: string
        email: string
        notifierContact: string
        recipientContactSms: string
        language: string
        status: string
        statusId: string
        asc: {
            createdDate: string
            expirationDate: string
            order: string
            amount: string
            email: string
            notifierContact: string
            recipientContactSms: string
            language: string
            status: string
            statusId: string
        }
        desc: {
            createdDate: string
            expirationDate: string
            order: string
            amount: string
            email: string
            notifierContact: string
            recipientContactSms: string
            language: string
            status: string
            statusId: string
        }
    }
    language: string
    languages: {
        eng: string
        rus: string
        kaz: string
    }
    postLinkStatuses: {
        ACTIVE: string
        ANNULLED: string
        EXPIRED: string
        CHARGED: string
    }
    tooltips: {
        copyLink: string
        cancelLink: string
    }
    sortBy: string
    installmentLabel: (value: string) => string | ReactNode
    inactiveLinkWarningTitle: string
    inactiveLinkWarning: string
}>()

import React from 'react'

const SvgQr = props => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M3 11h8V3H3v8zm2-6h4v4H5V5zM3 21h8v-8H3v8zm2-6h4v4H5v-4zm8-12v8h8V3h-8zm6 6h-4V5h4v4zm-5.99 4h2v2h-2v-2zm2 2h2v2h-2v-2zm-2 2h2v2h-2v-2zm4 0h2v2h-2v-2zm2 2h2v2h-2v-2zm-4 0h2v2h-2v-2zm2-6h2v2h-2v-2zm2 2h2v2h-2v-2z"
            fill="#F90"
        />
    </svg>
)

export default SvgQr

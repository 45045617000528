import { computed } from 'mobx'

import { AFTPayment } from '~/api'

import strict from '~/utils/strict'

import { SortOption } from '../../components/sort-config-select'

import res from '../res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{ title: string; items: Array<SortOption<AFTPayment>> }>
    >([
        {
            title: res().sortOptions.date,
            items: [
                {
                    field: 'createdDate',
                    direction: 'desc',
                    title: titles.desc.date
                },
                {
                    field: 'createdDate',
                    direction: 'asc',
                    title: titles.asc.date
                }
            ]
        },
        {
            title: res().sortOptions.postLink,
            items: [
                {
                    field: 'postLinkStatus',
                    direction: 'asc',
                    title: titles.asc.postLink
                },
                {
                    field: 'postLinkStatus',
                    direction: 'desc',
                    title: titles.desc.postLink
                }
            ]
        },
        {
            title: res().sortOptions.status,
            items: [
                {
                    field: 'status',
                    direction: 'asc',
                    title: titles.asc.status
                },
                {
                    field: 'status',
                    direction: 'desc',
                    title: titles.desc.status
                }
            ]
        },
        {
            title: res().sortOptions.amount,
            items: [
                {
                    field: 'amount',
                    direction: 'asc',
                    title: titles.asc.amount
                },
                {
                    field: 'amount',
                    direction: 'desc',
                    title: titles.desc.amount
                }
            ]
        },
        {
            title: res().sortOptions.senderCardMask,
            items: [
                {
                    field: 'senderCardMask',
                    direction: 'asc',
                    title: titles.asc.senderCardMask
                },
                {
                    field: 'senderCardMask',
                    direction: 'desc',
                    title: titles.desc.senderCardMask
                }
            ]
        },
        {
            title: res().sortOptions.description,
            items: [
                {
                    field: 'description',
                    direction: 'asc',
                    title: titles.asc.description
                },
                {
                    field: 'description',
                    direction: 'desc',
                    title: titles.desc.description
                }
            ]
        },
        {
            title: res().sortOptions.senderEmail,
            items: [
                {
                    field: 'senderEmail',
                    direction: 'asc',
                    title: titles.asc.senderEmail
                },
                {
                    field: 'senderEmail',
                    direction: 'desc',
                    title: titles.desc.senderEmail
                }
            ]
        },
        {
            title: res().sortOptions.fee,
            items: [
                {
                    field: 'fee',
                    direction: 'asc',
                    title: titles.asc.fee
                },
                {
                    field: 'fee',
                    direction: 'desc',
                    title: titles.desc.fee
                }
            ]
        },
        {
            title: res().sortOptions.orderId,
            items: [
                {
                    field: 'orderId',
                    direction: 'asc',
                    title: titles.asc.orderId
                },
                {
                    field: 'orderId',
                    direction: 'desc',
                    title: titles.desc.orderId
                }
            ]
        },
        {
            title: res().sortOptions.payerName,
            items: [
                {
                    field: 'payerName',
                    direction: 'asc',
                    title: titles.asc.payerName
                },
                {
                    field: 'payerName',
                    direction: 'desc',
                    title: titles.desc.payerName
                }
            ]
        },
        {
            title: res().sortOptions.payerPhone,
            items: [
                {
                    field: 'payerPhone',
                    direction: 'asc',
                    title: titles.asc.payerPhone
                },
                {
                    field: 'payerPhone',
                    direction: 'desc',
                    title: titles.desc.payerPhone
                }
            ]
        },
        {
            title: res().sortOptions.reference,
            items: [
                {
                    field: 'reference',
                    direction: 'asc',
                    title: titles.asc.reference
                },
                {
                    field: 'reference',
                    direction: 'desc',
                    title: titles.desc.reference
                }
            ]
        },
        {
            title: res().sortOptions.accountId,
            items: [
                {
                    field: 'accountId',
                    direction: 'asc',
                    title: titles.asc.accountId
                },
                {
                    field: 'accountId',
                    direction: 'desc',
                    title: titles.desc.accountId
                }
            ]
        }
    ])
})

export default options

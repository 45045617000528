import res from './res'

res.add('ru', {
    dataFields: {
        titles: {
            createdDate: 'Дата формирования',
            endDate: 'Дата окончания',
            order: 'Заказ',
            sum: 'Сумма',
            language: 'Язык оповещения',
            installment: 'Период рассрочки',
            invoice_alt: 'Альтернативный номер заказа',
            status: 'Статус',
            merchantId: 'ID Мерчанта',
            description: 'Описание',
            emailToSend: 'Email для отправки',
            phoneToSend: 'Телефон для отправки',
            notificationEmail: 'Email для уведомления',
            notificationPhone: 'Телефон для уведомления'
        }
    },
    sortOptions: {
        createdDate: 'Дате создания',
        expirationDate: 'Дате окончания',
        order: 'Заказу',
        amount: 'Сумма',
        email: 'Email',
        notifierContact: 'Email',
        recipientContactSms: 'Телефон для уведомления',
        language: 'Язык оповещения',
        status: 'Статус',
        statusId: 'Статус',
        asc: {
            createdDate: 'cтарые вначале',
            expirationDate: 'по возрастанию',
            order: 'по возрастанию',
            amount: 'по возрастанию',
            email: 'A → Z',
            notifierContact: 'A → Z',
            recipientContactSms: 'по возрастанию',
            language: 'A → Z',
            status: 'активные в начале',
            statusId: 'активные в начале'
        },
        desc: {
            createdDate: 'новые вначале',
            expirationDate: 'по убыванию',
            order: 'по убыванию',
            amount: 'по убыванию',
            email: 'Z ← A',
            notifierContact: 'Z ← A',
            recipientContactSms: 'по убыванию',
            language: 'Z ← A',
            status: 'неактивные в начале',
            statusId: 'неактивные в начале'
        }
    },
    language: 'Язык',
    languages: {
        eng: 'Английский',
        rus: 'Русский',
        kaz: 'Казахский'
    },
    postLinkStatuses: {
        ACTIVE: 'Активная',
        ANNULLED: 'Аннулированая',
        EXPIRED: 'Просроченная',
        CHARGED: 'Списанная'
    },
    tooltips: {
        copyLink: 'Скопировать ссылку',
        cancelLink: 'Аннулировать ссылку'
    },
    sortBy: 'Сортировать по',
    installmentLabel: (val: string) => {
        const value = parseInt(val, null)

        if (!value || value === 0) return 'Без рассрочки'

        if (value >= 5 && value <= 20) return `${value} месяцев`

        const lastDigit = value % 10

        if (lastDigit === 1) return `${value} месяц`

        if (lastDigit >= 2 && lastDigit <= 4) return `${value} месяца`

        return `${value} месяцев`
    },
    inactiveLinkWarningTitle: 'Внимание',
    inactiveLinkWarning: 'Эта ссылка истекла, была списана, либо была отменена.'
})

import res from './res'

import { CreditType, PaymentStatus } from '~/api'

res.add('en', {
    title: 'ePay payments',
    ranges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter',
        customPeriod: 'custom period'
    },
    anyPaymentStatus: 'Any',
    paymentStatuses: {
        [PaymentStatus.auth]: 'Pending',
        [PaymentStatus.charged]: 'Charged',
        [PaymentStatus.cancelled]: 'Cancelled',
        [PaymentStatus.rejected]: 'Rejected',
        [PaymentStatus.refunded]: 'Refunded',
        [PaymentStatus.failed]: 'Failed',
        [PaymentStatus.new]: 'New',
        [PaymentStatus.any]: 'Any',
        [PaymentStatus.CANCEL_OLD]: 'Cancel old',
        [PaymentStatus.TIMEOUT_3D]: '3DS Cancelled',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'In progress',
        [PaymentStatus.PENDING]: 'Pending',
        [PaymentStatus.SUCCESS]: 'Success',
        [PaymentStatus.VERIFIED]: 'Verified'
    },
    creditTypes: {
        [CreditType.loan]: 'Loan',
        [CreditType.installment]: 'Installment'
    },
    sortBy: 'Sort by',
    filtersPlaceholder: 'Payment attributes filter',
    dataFields: {
        full: {
            accountId: 'Customer identifier',
            amount: 'Payment amount',
            amountBonus: 'Go!-bonuses amount',
            authDate: 'Authorized on',
            cardMask: 'Card mask',
            cardType: 'Card type',
            chargeDate: 'Charged on',
            client: 'Customer',
            createdDate: 'Created on',
            currency: 'Currency',
            data: 'Additional fields',
            description: 'Payment details',
            fee: 'Fee',
            installment_id: 'Installment',
            invoiceId: 'Order No.',
            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',
            issuer: 'Issuer',
            issuerBankCountry: 'Issuer country',
            language: 'Language',
            merchant: 'Merchant reference',
            payerEmail: 'Customer email',
            payerIp: 'Customer IP',
            payerName: 'Card owner name',
            payerPhone: 'Customer phone',
            postLink: 'PostLink address',
            postLinkStatus: 'PostLink',
            reference: 'Retrieval reference number',
            payoutAmount: 'Refund amount',
            payoutDate: 'Refund date',
            secure3D: 'Is 3D secure',
            shop: 'Shop name',
            status: 'Payment status',
            resultMessage: 'Result',
            template: 'Billing page template',
            orgAmount: 'Initial amount',
            transactionTypes: 'Transaction types',
            paymentType: 'Payment method',
            approvalCode: 'Authorization code',
            invoiceIdAlt: 'Alternative Order No.',
            user: 'Пользоваетль',
            userIp: 'IP-пользователя',
            purpose: 'Комментарий',
            creditType: 'Credit type',
            qrReference: 'QR Reference',
            secureDetails: 'Secure Details',
            xlsTrxIdCharge: 'RNN Charge'
        },
        short: {
            accountId: 'Customer ID',
            amount: 'Amount',
            amountBonus: 'Go!bonus',
            approvalCode: 'Authorization code',
            authDate: 'Authorized On',
            cardMask: 'Card',
            cardType: 'Card type',
            chargeDate: 'Charged On',
            client: 'Customer',
            createdDate: 'Date',
            creditType: 'Credit type',
            currency: 'Currency',
            data: 'Additional fields',
            description: 'Description',
            fee: 'Fee',
            installment_id: 'Installment',
            invoiceId: 'Order #',
            invoiceIdAlt: 'Alternative Order #',
            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',
            issuer: 'Issuer',
            issuerBankCountry: 'Issuer country',
            language: 'Language',
            merchant: 'Merchant reference',
            orgAmount: 'Initial amount',
            payerEmail: 'Email',
            payerIp: 'Payer IP',
            payerName: 'Payer',
            payerPhone: 'Phone',
            paymentType: 'Payment method',
            payoutAmount: 'Refund amount',
            payoutDate: 'Refunded On',
            postLink: 'PostLink address',
            postLinkStatus: 'PostLink',
            purpose: 'Comments',
            qrReference: 'QR Reference',
            reference: 'RRN',
            resultMessage: 'Result',
            secure3D: 'Is 3D secure',
            shop: 'Shop',
            status: 'Status',
            template: 'Payment page template',
            transactionTypes: 'Transaction types',
            user: 'User',
            userIp: 'User Ip',
            secureDetails: 'Secure Details',
            xlsTrxIdCharge: 'RNN Charge'
        },
        groups: {
            location: 'Location',
            paymentPage: 'Payment Page'
        },
        geolocation: 'Geolocation'
    },
    sortOptions: {
        accountId: 'Customer ID',
        amount: 'Amount',
        amountBonus: 'Go!bonus',
        approvalCode: 'Authorization code',
        cardMask: 'Card',
        chargeback: 'chargeback',
        date: 'Date',
        description: 'Description',
        email: 'Email',
        fee: 'Fee',
        invoiceId: 'Order #',
        invoiceIdAlt: 'Alternative Order #',
        orgAmount: 'Initial amount',
        payerName: 'Payer',
        payerPhone: 'Phone',
        payerEmail: 'Email',
        paymentType: 'Payment method',
        payoutAmount: 'Refund amount',
        payoutDate: 'Refunded On',
        postLink: 'PostLink',
        purpose: 'Purpose',
        qrReference: 'QR Reference',
        reference: 'RRN',
        resultMessage: 'Result',
        status: 'Status',
        statusId: 'Status',
        shopId: 'Shop',
        type: 'Type',
        user: 'User',
        userIp: 'User ip',
        asc: {
            accountId: 'A → Z',
            amount: 'asc',
            amountBonus: 'asc',
            approvalCode: 'asc',
            cardMask: 'asc',
            chargeback: 'asc',
            date: 'old first',
            description: 'A → Z',
            email: 'A → Z',
            fee: 'asc',
            invoiceId: 'asc',
            invoiceIdAlt: 'asc',
            orgAmount: 'asc',
            payerName: 'A → Z',
            payerPhone: 'A → Z',
            payerEmail: 'A → Z',
            paymentType: 'asc',
            payoutAmount: 'asc',
            payoutDate: 'old first',
            postLink: 'successful first',
            purpose: 'A → Z',
            qrReference: 'A → Z',
            reference: 'A → Z',
            resultMessage: 'A → Z',
            status: 'pending first',
            statusId: 'pending first',
            shopId: 'asc',
            type: 'A → Z',
            user: 'A → Z',
            userIp: 'asc'
        },
        desc: {
            date: 'new first',
            postLink: 'failed first',
            status: 'refund first',
            statusId: 'refund first',
            invoiceId: 'desc',
            accountId: 'Z ← A',
            amount: 'desc',
            amountBonus: 'desc',
            approvalCode: 'desc',
            cardMask: 'desc',
            chargeback: 'desc',
            description: 'Z ← A',
            email: 'Z ← A',
            fee: 'desc',
            invoiceIdAlt: 'desc',
            orgAmount: 'desc',
            payerName: 'Z ← A',
            payerPhone: 'Z ← A',
            payerEmail: 'Z ← A',
            paymentType: 'desc',
            payoutAmount: 'desc',
            payoutDate: 'old first',
            purpose: 'Z ← A',
            qrReference: 'Z ← A',
            reference: 'desc',
            resultMessage: 'Z ← A',
            shopId: 'desc',
            type: 'Z ← A',
            user: 'Z ← A',
            userIp: 'desc'
        }
    },
    installments: {
        full: {
            '3m': '3 months',
            '6m': '6 months',
            '9m': '9 months',
            '12m': '12 months'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secure3DValues: {
        true: 'Yes',
        false: 'No'
    },
    partialSum: {
        isRequired: 'Sum field is required',
        constraints: 'Sum must be a positive number not greater than the payment sum'
    },
    operations: {
        fullCharge: {
            title: 'Full charge',
            itemError: paymentReference => `Error full charge on payment #${paymentReference}`
        },
        partialCharge: {
            title: 'Partial charge',
            itemError: paymentReference => `Error partial charge on payment #${paymentReference}`
        },
        cancel: {
            title: 'Cancel',
            itemError: paymentReference => `Error cancellation on payment #${paymentReference}`
        },
        fullRefund: {
            title: 'Full refund',
            itemError: paymentReference => `Error full refund on payment #${paymentReference}`
        },
        partialRefund: {
            title: 'Partial refund',
            itemError: paymentReference => `Error partial refund on payment #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'All',
        ok: 'OK',
        fail: 'Failed'
    },
    collapseSummary: range => `Statistics for ${range instanceof Function ? range() : range || 'period'}`,
    errors: {
        summaryLoadError: 'Summary load error',
        paymentsLoadError: 'Payments load error'
    },
    chargeback: {
        shared_secret: 'Shared secret',
        invoice_id: 'Invoice ID',
        operation: 'Operation',
        operation_id: 'Operation ID',
        status: 'Status',
        amount: 'Amount',
        ARN: 'ARN',
        authCode: 'auth Code',
        cardBin: 'BIN',
        transAmount: 'Transfer amount',
        chargebackDate: 'Chargeback date',
        chargebackReasonCode: 'Code reason',
        RRN: 'RRN',
        transDate: 'Transfer date',
        transCurrency: 'Transfer currency',
        yes: 'Yes',
        no: 'No'
    },
    errorCodes: {
        454: 'The operation failed, please check the amount on the card is not blocked and try again later.',
        455: 'Authentication failed (3DSecure/Securecode).',
        456: 'Error please try later',
        457: 'Error in card expiration date field.',
        458: 'Server is not responding.',
        459: 'Server is not responding.',
        460: 'An error occurred, the payment amount may be blocked, please contact customer support.',
        461: 'System error. Please try later. If this problem continues, please contact the customer support.',
        462: 'Call your bank.',
        463: 'Call your bank.',
        464: 'Invalid merchant.',
        465: 'Your card is restricted.',
        466: 'Transaction declined.Call your bank.',
        467: 'Your card is disabled.',
        468: 'Additional identification required.',
        469: 'Invalid transaction.',
        470: 'Invalid amount.',
        471: 'No such card.',
        472: 'No such card.',
        473: 'Authentication failed (3DSecure/Securecode).',
        475: 'Invalid response.',
        476: 'No action taken.',
        477: 'Format error.',
        478: 'Expired card.',
        479: 'Restricted card.',
        480: 'Call your bank.',
        481: 'Lost card.',
        482: 'Lost card.',
        483: 'Stolen card.',
        484: 'Not sufficient funds.',
        485: 'Expired card.',
        486: 'Not permitted to client by Issuing bank. Call your bank.',
        487: 'Not permitted to merchant.',
        488: 'Exceeds amount limit.',
        489: 'Restricted card.',
        490: 'The ban on the payments on your card, for more information, please contact customer support. Contact info is stated on the back of your card.',
        491: 'Exceeds frequency limit.',
        492: 'The card blocked due to incorrect entering pin-code. For the further information please contact customer support, contact info is stated on the back of your card.',
        493: 'Time-out at issuer system.',
        494: 'Issuer unavailable.',
        495: 'The transaction is prohibited. Please use other card.',
        496: '3DSecure SecureCode absent.',
        497: 'Server is not responding. Please try later.',
        498: 'Payment with bonuses is not available. Please try again later.',
        499: 'Incorrectly entered or not entered 3DSecure/Securecode',
        500: '3DSecure / Securecode verification is not available or you entered card number incorrectly. Try to use a different browser/device. If the error persists, reset the password.',
        501: 'Error in servicing the card. Be sure to enter the card number correctly. If the error persists, contact support.',
        502: 'This type of transaction requires the mandatory input of the password 3DSecure/Securecode.',
        503: 'Sorry! This operation requires you to use you 3D Secure password.',
        521: 'Transaction declined.Call your bank.',
        522: 'Record Not Found, check the card.',
        1636: 'Failed to establish the owner of the card.',
        1654: 'Failed to verify OTP code.',
        2092: 'Unable to pay with this car.',
        2678: 'Exceeded the daily limit on the terminal.',
        2740: 'Cannot approve at this time (restricted error code)',
        2872: 'Do not reattempt (restricted error code)'
    }
})

import res from './res'

import { PaymentStatus } from '~/api'

res.add('en', {
    title: 'Pos payments',
    ranges: {
        today: 'today',
        yesterday: 'yesterday',
        thisWeek: 'this week',
        priorWeek: 'prior week',
        thisMonth: 'this month',
        priorMonth: 'prior month',
        thisQuarter: 'this quarter'
    },
    anyPaymentStatus: 'Any',
    paymentStatuses: {
        [PaymentStatus.auth]: 'Pending',
        [PaymentStatus.charged]: 'Charged',
        [PaymentStatus.cancelled]: 'Cancelled',
        [PaymentStatus.rejected]: 'Cancelled',
        [PaymentStatus.refunded]: 'Refunded',
        [PaymentStatus.failed]: 'Failed',
        [PaymentStatus.new]: 'New',
        [PaymentStatus.any]: 'Any',
        [PaymentStatus.CANCEL_OLD]: 'Cancel old',
        [PaymentStatus.TIMEOUT_3D]: '3DS Cancelled',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'In progress',
        [PaymentStatus.PENDING]: 'Pending',
        [PaymentStatus.SUCCESS]: 'Success',
        [PaymentStatus.VERIFIED]: 'Verified'
    },
    sortBy: 'Sort by',
    filtersPlaceholder: 'Payment attributes filter',
    dataFields: {
        full: {
            accountId: 'Customer identifier',
            amount: 'Payment amount',
            amountBonus: 'Go!-bonuses amount',
            authDate: 'Authorized on',
            cardMask: 'Card mask',
            cardType: 'Card type',
            chargeDate: 'Charged on',
            client: 'Customer',
            createdDate: 'Created on',
            currency: 'Currency',
            data: 'Additional fields',
            description: 'Payment details',
            fee: 'Fee',
            installment_id: 'Installment',
            invoiceId: 'Order No.',
            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',
            issuer: 'Issuer',
            issuerBankCountry: 'Issuer country',
            language: 'Language',
            merchant: 'Merchant reference',
            payerEmail: 'Customer email',
            payerIp: 'Customer IP',
            payerName: 'Card owner name',
            payerPhone: 'Customer phone',
            postLink: 'PostLink address',
            postLinkStatus: 'PostLink',
            reference: 'Retrieval reference number',
            payoutAmount: 'Refund amount',
            payoutDate: 'Refund date',
            secure3D: 'Is 3D secure',
            shop: 'Shop name',
            status: 'Payment status',
            template: 'Billing page template',
            qrReference: 'QR Reference'
        },
        short: {
            accountId: 'Customer ID',
            amount: 'Amount',
            amountBonus: 'Go!bonus',
            authDate: 'Authorized On',
            cardMask: 'Card',
            cardType: 'Card type',
            chargeDate: 'Charged On',
            client: 'Customer',
            createdDate: 'Date',
            currency: 'Currency',
            data: 'Additional fields',
            description: 'Description',
            fee: 'Fee',
            installment_id: 'Installment',
            invoiceId: 'Order #',
            ipCity: 'City',
            ipCountry: 'Country',
            ipDistrict: 'District',
            ipLatitude: 'Latitude',
            ipLongitude: 'Longitude',
            ipRegion: 'Region',
            issuer: 'Issuer',
            issuerBankCountry: 'Issuer country',
            language: 'Language',
            merchant: 'Merchant reference',
            payerEmail: 'Email',
            payerIp: 'Payer IP',
            payerName: 'Payer',
            payerPhone: 'Phone',
            postLink: 'PostLink address',
            postLinkStatus: 'PostLink',
            reference: 'RRN',
            payoutAmount: 'Refund amount',
            payoutDate: 'Refunded On',
            secure3D: 'Is 3D secure',
            shop: 'Shop',
            status: 'Status',
            template: '?',
            qrReference: 'QR Reference'
        },
        groups: {
            location: 'Location',
            paymentPage: 'Payment Page'
        },
        geolocation: 'Geolocation'
    },
    sortOptions: {
        date: 'Date',
        postLink: 'PostLink',
        status: 'Status',
        type: 'Type',
        asc: {
            date: 'old first',
            postLink: 'successful first',
            status: 'pending first'
        },
        desc: {
            date: 'new first',
            postLink: 'failed first',
            status: 'refund first'
        }
    },
    installments: {
        full: {
            '3m': '3 months',
            '6m': '6 months',
            '9m': '9 months',
            '12m': '12 months'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secure3DValues: {
        true: 'Yes',
        false: 'No'
    },
    partialSum: {
        isRequired: 'Sum field is required',
        constraints: 'Sum must be a positive number not greater than the payment sum'
    },
    operations: {
        fullCharge: {
            title: 'Full charge',
            itemError: paymentReference => `Error full charge on payment #${paymentReference}`
        },
        partialCharge: {
            title: 'Partial charge',
            itemError: paymentReference => `Error partial charge on payment #${paymentReference}`
        },
        cancel: {
            title: 'Cancel',
            itemError: paymentReference => `Error cancellation on payment #${paymentReference}`
        },
        fullRefund: {
            title: 'Full refund',
            itemError: paymentReference => `Error full refund on payment #${paymentReference}`
        },
        partialRefund: {
            title: 'Partial refund',
            itemError: paymentReference => `Error partial refund on payment #${paymentReference}`
        }
    },
    postLinkStatus: {
        all: 'All',
        ok: 'OK',
        fail: 'Failed'
    },
    collapseSummary: range => `Statistics for ${range instanceof Function ? range() : range || 'period'}`,
    errors: {
        summaryLoadError: 'Summary load error',
        paymentsLoadError: 'Payments load error'
    }
})

import { PaymentStatus } from '~/api'

import res from './res'

res.add('ru', {
    loading: 'Загрузка статистики ...',
    failed: 'Статистика недоступна :(',
    statuses: {
        [PaymentStatus.auth]: 'Ожидают',
        [PaymentStatus.charged]: 'Проведено',
        [PaymentStatus.cancelled]: 'Отменено',
        [PaymentStatus.rejected]: 'Отменено',
        [PaymentStatus.refunded]: 'Возвраты',
        [PaymentStatus.failed]: 'Неудачные',
        [PaymentStatus.new]: 'Новые',
        [PaymentStatus.any]: 'Любой',
        [PaymentStatus.CANCEL_OLD]: 'Отменен по сроку',
        [PaymentStatus.TIMEOUT_3D]: '3DS Отменено',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'В процессе',
        [PaymentStatus.PENDING]: 'В ожидании',
        [PaymentStatus.SUCCESS]: 'Успешный',
        [PaymentStatus.VERIFIED]: 'Проверено'
    }
})

import { action, computed, observable, reaction, runInAction } from 'mobx'
import amplitude from 'amplitude-js'
import moment from 'moment'

import { apiCheck, refundCreditP2P } from '~/api'
import {
    ApiResponse,
    cancelP2P,
    chargeP2P,
    P2PFilterableField,
    P2PTransfer,
    P2PTransferRequest
} from '~/api'
import {
    P2PSortableField,
    getP2PTransfers,
    getP2PSummary,
    P2PStatus,
    refundP2P
} from '~/api'
import { SearchParameter } from '~/api/contracts'
import {
    P2PTransferResponse,
    P2PSummary,
    P2PSummaryRequest
} from '~/api/p2p/contracts'

import { camelCaseToSnakeCase } from '~/utils'
import FieldStore from '~/utils/field-store'
import strict from '~/utils/strict'
import { numeric, required } from '~/utils/validation'

import { AmountsByCurrencies } from '../types'

import { RangePopoverValue } from '~/components/range-popover'

import {
    PaymentFiltersInterface,
    PaymentsPageStore
} from '../components/payments-page'

import constants from './constants'

import { FilterValues } from '~/components/data-filter'
import { LoadRequestInterface } from '~/components/list/infinitive-list'
import { noThrow } from '~/utils/control-flow'
import inAction from '~/utils/in-action'
import message from '~/utils/message'
import res from './res'

export interface EPaymentFiltersInterface
    extends PaymentFiltersInterface<P2PTransfer> {
    postLinkStatusOptionKey: string
}

const lastDefaultVisibleColumn = 8

export default class P2PTransfersStore extends PaymentsPageStore<P2PTransfer> {
    constructor() {
        super()

        amplitude.getInstance().logEvent('user_visited_epay_payments')

        this.partialChargeAmount = new FieldStore<string>(
            this.partialValidations
        )

        this.partialRefundAmount = new FieldStore<string>(
            this.partialValidations
        )

        reaction(
            () => this.paymentsRequest,
            () => this.loadPayments(),
            { fireImmediately: true }
        )
        reaction(() => this.summaryRequest, this.loadSummary, {
            fireImmediately: true
        })

        this._requestSearchParameters.observe(
            inAction(() => (this.pageIndex = 0))
        )
    }

    public partialChargeAmount: FieldStore<string>

    public partialRefundAmount: FieldStore<string>

    @observable.ref
    public summaryData: P2PSummary = null

    @observable
    public showSummary: boolean

    @observable
    public showSummaryMobile: boolean = false

    @observable
    public showSortingMobile: boolean = false

    @observable
    public showFilteringMobile: boolean = false

    @computed
    public get rangePresets() {
        const today = moment().startOf('day')

        const yesterday = today.clone().add(-1, 'days')

        const thisWeek = today.clone().startOf('isoWeek')

        const prevWeek = thisWeek.clone().add(-1, 'week')

        const thisMonth = today.clone().startOf('month')

        const prevMonth = thisMonth.clone().add(-1, 'month')

        const thisQuarter = today.clone().startOf('quarter')

        return strict<RangePopoverValue[]>([
            {
                key: 'today',
                label: () => res().ranges.today,
                range: [today, today.clone().endOf('day')]
            },
            {
                key: 'yesterday',
                label: () => res().ranges.yesterday,
                range: [yesterday, yesterday.clone().endOf('day')]
            },
            {
                key: 'thisWeek',
                label: () => res().ranges.thisWeek,
                range: [thisWeek, thisWeek.clone().endOf('isoWeek')]
            },
            {
                key: 'priorWeek',
                label: () => res().ranges.priorWeek,
                range: [prevWeek, prevWeek.clone().endOf('isoWeek')]
            },
            {
                key: 'thisMonth',
                label: () => res().ranges.thisMonth,
                range: [thisMonth, thisMonth.clone().endOf('month')]
            },
            {
                key: 'priorMonth',
                label: () => res().ranges.priorMonth,
                range: [prevMonth, prevMonth.clone().endOf('month')]
            },
            {
                key: 'thisQuarter',
                label: () => res().ranges.thisQuarter,
                range: [thisQuarter, thisQuarter.clone().endOf('quarter')]
            },
            {
                key: 'customPeriod',
                label: () => res().ranges.customPeriod,
                range: [undefined, undefined]
            }
        ])
    }

    public getPayments(
        request: P2PTransferRequest
    ): Promise<ApiResponse<P2PTransferResponse>> {
        return getP2PTransfers(request)
    }

    @action.bound
    public setStatusFilter(value: P2PStatus) {
        this.statusFilter = value
    }

    @computed
    public get summaryStatisticsForPeriod() {
        return this.summaryData.timeline
    }

    @computed
    public get summaryStatisticsByPaymentsSystems() {
        return this.summaryData.byPaymentSystem
    }

    @computed
    public get summaryTotalStatisticsByStatuses() {
        return this.summaryData.byStatus
    }

    @computed
    public get selectedPaymentAmounts(): AmountsByCurrencies {
        const result = {}

        for (const p of this.selectedPayments) {
            result[p.currency] = (result[p.currency] || 0) + p.amount
        }

        return result
    }

    @computed
    public get selectedPaymentStatus() {
        const { selectedPayments } = this

        if (selectedPayments.length === 0) {
            return undefined
        }

        const code = selectedPayments[0].code

        return selectedPayments.every(p => p.code === code) ? code : null
    }

    @action.bound
    public toggleSummary() {
        this.showSummary = !this.showSummary
    }

    @action.bound
    public refresh() {
        this.loadPayments()
        this.loadSummary()
    }

    @action.bound
    public toggleSummaryMobile() {
        this.showSummaryMobile = !this.showSummaryMobile
    }

    @action.bound
    public toggleSortingMobile() {
        this.showSortingMobile = !this.showSortingMobile
    }

    @action.bound
    public toggleFilteringMobile() {
        this.showFilteringMobile = !this.showFilteringMobile
    }

    @action.bound
    public async chargePaymentsInFull(paymentsKeys?: string[]) {
        const localization = res().operations.fullCharge

        this.process(
            chargeP2P,
            res().title,
            paymentId => localization.itemError(paymentId),
            paymentsKeys
        )

        amplitude
            .getInstance()
            .logEvent('user_fully_charged', { payments: paymentsKeys })
    }

    @action.bound
    public async chargePaymentPartial(paymentsKeys?: string[]) {
        const amountFieldStore = this.partialChargeAmount
        const localization = res().operations.partialCharge

        if (!amountFieldStore.isValid()) return

        this.process(
            chargeP2P,
            localization.title,
            paymentId => localization.itemError(paymentId),
            paymentsKeys,
            parseFloat(amountFieldStore.value)
        )
        amplitude.getInstance().logEvent('user_partially_charged', {
            amount: amountFieldStore.value,
            payments: paymentsKeys
        })
    }

    @action.bound
    public async cancelPayments(paymentsKeys?: string[]) {
        const localization = res().operations.cancel
        this.process(
            cancelP2P,
            localization.title,
            paymentId => localization.itemError(paymentId),
            paymentsKeys
        )
        amplitude
            .getInstance()
            .logEvent('user_cancelled_payment', { payments: paymentsKeys })
    }

    @action.bound
    public async refundPaymentsInFull(paymentsKeys?: string[], creditPaymentInfo?: any) {
        const localization = res().operations.fullRefund

        this.process(
            refundP2P,
            localization.title,
            paymentId => localization.itemError(paymentId),
            paymentsKeys,
            creditPaymentInfo?.amount ? creditPaymentInfo.amount : null,
            creditPaymentInfo?.orderId ? creditPaymentInfo.orderId : null
        )
        amplitude
            .getInstance()
            .logEvent('user_fully_refunded', { payments: paymentsKeys })
    }

    @action.bound
    public async refundPaymentPartial(paymentsKeys?: string[], creditPaymentInfo?: any) {
        const amountFieldStore = this.partialRefundAmount
        const localization = res().operations.partialRefund

        if (!amountFieldStore.isValid()) return

        this.process(
            refundP2P,
            localization.title,
            paymentId => localization.itemError(paymentId),
            paymentsKeys,
            +amountFieldStore.value,
            creditPaymentInfo?.orderId ? creditPaymentInfo.orderId : null
        )

        amplitude.getInstance().logEvent('user_partially_refunded', {
            amount: amountFieldStore.value,
            payments: paymentsKeys
        })
    }

    public async getPaymentsByIds(
        ids: string[]
    ): Promise<ApiResponse<P2PTransferResponse>> {
        return await getP2PTransfers({
            orderParameters: [],
            searchParameters: this.getRangeParameters().concat([
                {
                    name: 'id',
                    method: 'in',
                    searchParameter: ids
                }
            ]),
            paging: { page: 0, size: ids.length }
        })
    }
    public isFilterEmpty(filterParams: EPaymentFiltersInterface) {
        const baseFiltersEmpty = super.isFilterEmpty(filterParams)
        const isPostlinkStatusEmpty =
            filterParams.postLinkStatusOptionKey === 'all'
        return isPostlinkStatusEmpty ? baseFiltersEmpty : false
    }
    @action.bound
    public prepareFilter(
        filters: FilterValues<P2PTransfer>,
        filterParams: EPaymentFiltersInterface
    ) {
        const postLinkStatusFieldKey = 'postLinkStatus'
        if (filterParams.postLinkStatusOptionKey !== 'all') {
            filters[postLinkStatusFieldKey] = {
                equals: filterParams.postLinkStatusOptionKey === 'ok'
            }
        } else {
            delete filters[postLinkStatusFieldKey]
        }
        return filters
    }

    @action.bound
    public async load(requestParams?: LoadRequestInterface) {
        this.loadSummary()
        this.loadPayments(requestParams)
    }

    protected setup() {
        this.columns = constants.dataFields.get().map((item, index) => {
            return {
                field: item.field,
                width: +item.width || 150,
                visible: index <= lastDefaultVisibleColumn
            }
        })

        this.range = this.rangePresets[2]

        this.sortField = 'createdDate'
        this.sortDirection = 'desc'
        this.statusFilter = undefined
    }

    protected get storageKey() {
        return 'p2p-transfer'
    }

    protected get prefs() {
        return {
            range:
                this.range.key && this.range.key !== 'customPeriod'
                    ? { key: this.range.key }
                    : { range: this.range.range },
            filters: this.filters,
            showSummary: this.showSummary
        }
    }

    protected set prefs(value) {
        const { range, ...rest } = value

        Object.assign(this, rest)

        if (range) {
            const key = range.key

            if (key) {
                const preset = this.rangePresets.find(r => r.key === key)
                if (preset) {
                    this.range = preset
                    return
                }
            }

            this.range = {
                range: [moment(range.range[0]), moment(range.range[1])]
            }
        }
    }

    @action.bound
    private async loadSummary() {
        this.summaryLoading = true
        this.summaryFailed = false

        const { value, error } = await noThrow(
            apiCheck(getP2PSummary(this.summaryRequest))
        )

        runInAction(() => {
            this.summaryLoading = false
            this.summaryFailed = error != null
            if (error) {
                return message.error(error, res().errors.summaryLoadError)
            }
            this.summaryData = value
        })
    }

    @action.bound
    private async loadPayments(requestParams?: LoadRequestInterface) {
        this.paymentsLoading = true
        this.paymentsFailed = false
        let paymentsRequest: P2PTransferRequest
        if (requestParams) {
            const pageSize = this.paymentsRequest.paging.size
            const paging = {
                ...this.paymentsRequest.paging,
                page:
                    requestParams.startIndex + 1 < pageSize
                        ? 1
                        : requestParams.startIndex / pageSize
            }
            paymentsRequest = { ...this.paymentsRequest, paging }
        } else {
            paymentsRequest = this.paymentsRequest
        }
        const { value, error } = await noThrow(
            apiCheck(this.getPayments(paymentsRequest))
        )

        runInAction(() => {
            this.paymentsLoading = false
            this.paymentsFailed = !error
            if (error) {
                return message.error(error, res().errors.paymentsLoadError)
            }

            if (requestParams) {
                this.data = value?.records
                    ? this.data.concat(value?.records)
                    : this.data
            } else {
                this.data = value?.records ? value?.records : []
            }
            this.selectedPaymentKeys = this.selectedPaymentKeys.filter(key => {
                return value?.records.find(operation => operation.id === key)
            })

            if (this.data) {
                const skip = this.pageIndex * this.pageSize
                this.pageInfo = {
                    from: skip + 1,
                    to: skip + this.data.length,
                    total: value?.totalCount
                }
            } else {
                this.pageInfo = { from: 0, to: 0, total: 0 }
            }
        })
    }

    @computed
    public get paymentsRequest() {
        const { pageSize, pageIndex, _requestSearchParameters } = this

        const request = {
            paging: {
                page: pageIndex * pageSize,
                size: pageSize
            },
            searchParameters: this._requestSearchParameters.get(),
            orderParameters: [
                {
                    field: camelCaseToSnakeCase(
                        this.sortField
                    ) as P2PSortableField,
                    typeOrder: this.sortDirection.toUpperCase()
                },
                {
                    field: 'id',
                    typeOrder: this.sortDirection.toUpperCase()
                }
            ]
        } as P2PTransferRequest

        return request
    }

    @computed
    private get summaryRequest() {
        const { range } = this

        return {
            from: range.range[0].startOf('day'),
            to: range.range[1].endOf('day')
        } as P2PSummaryRequest
    }

    private getRangeParameters(): Array<SearchParameter<P2PFilterableField>> {
        const rangeParameter =
            this.range && this.range.range[0] && this.range.range[1]
                ? [
                    this.range.range[0].startOf('day').toDate(),
                    this.range.range[1].endOf('day').toDate()
                ]
                : [
                    this.rangePresets[2].range[0].startOf('day'),
                    this.rangePresets[2].range[1].endOf('day')
                ]
        return [
            {
                name: 'created_date',
                method: 'between',
                searchParameter: rangeParameter
            }
        ]
    }

    private _requestSearchParameters = computed(() => {
        const result = this.getRangeParameters()

        const code = this.statusFilter

        if (code) {
            result.push({
                name: 'status_id',
                method: '=',
                searchParameter: [code]
            })
        }

        super.addFiltersToSearchParameters(result)

        return result
    })

    private partialValidations = [
        required(res().partialSum.isRequired),
        numeric(res().partialSum.constraints),
        {
            rule: (value: string) => {
                const num = +value

                const paymentAmount = this.selectedPayments[0]
                    ? this.selectedPayments[0].amount
                    : this.detailedPayment
                        ? this.detailedPayment.amount
                        : 0
                return num >= 0 && num <= paymentAmount
            },
            message: res().partialSum.constraints
        }
    ]
}

import React from 'react'
import res from './res'

res.add('ru', {
    title: 'Формирование ссылки на оплату',
    dataToSendLink: 'Данные для отправки ссылки',
    generalData: 'Общие данные',
    chooseStoreLabel: 'Выберите магазин',
    invoicePlaceholder: 'Введите номер инвойса',
    invoiceAltPlaceholder: 'Введите альтернативный номер инвойса',
    accountIdPlaceholder: 'Введите номер клиента',
    paymentNotificationData: 'Данные для уведомления об оплате',
    fieldErrorMessage: 'Пожалуйста, проверьте это поле',
    generateButton: 'Сформировать',
    applyButton: 'Применить',
    closeButton: 'Закрыть',
    months: 'месяцев',
    notRequired: 'не обязательно',
    cancelButton: 'Отменить',
    withoutInstallment: 'Без рассрочки',
    installmentTooltip:
        'Вы можете продавать свои товары в рассрочку. Для этого необходимо подключить услугу "Рассрочка"',
    invalidShop: 'Выберите магазин',
    invalidAccountId:
        'Идентификатор покупателя должен быть от 8 до 30 символов и не иметь букв',
    invalidAmount: 'Введите сумму не менее 100 в данном формате (100.00)',
    descriptionRequired: 'Описание не должно быть пустым',
    descriptionMaxLength: 'Описание не должно превышать лимит символов',
    invalidEmail: 'Введите email правильного формата (example@email.co)',
    invalidOrderId:
        'Номер должен быть между 8 и 16 символов и состоять только из номерных символов',
    invalidPhone: 'Введите номер телефона верного формата',
    formItemTitles: {
        orderNumber: 'Номер заказа',
        invoiceAlt: 'Альтернативный номер заказа',
        orderSum: 'Сумма заказа',
        accountId: 'Идентификатор клиента',
        language: 'Язык сообщения',
        recieverEmail: 'Email получателя',
        recieverPhone: 'Номер телефона получателя SMS',
        senderEmail: 'Email для уведомления',
        senderPhone: 'Номер телефона для уведомления',
        email: 'Email',
        phone: 'Номер телефона',
        description: 'Описание заказа',
        expirePeriod: 'Cрок действия ссылки (дней)'
    },
    confirmationTitle: (orderNumber: string) => (
        <div>
            Ссылка на оплату по заказу {orderNumber} <br /> сформирована и
            отправлена
        </div>
    ),
    clipBoardMessage: 'Ссылка скопирована',
    sumError: 'Введите сумму в формате: 100',
    duplicateOrderIdError:
        'Указаный номер заказа уже существует. Пожалуйста введите другой номер заказа.',
    serverError: 'Ошибка при запросе на сервер',
    noShopsError:
        'Не найдены активные магазины. Пожалуйста, обратитесь в службу поддержки.'
})

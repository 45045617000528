import { computed } from 'mobx'

import strict from '~/utils/strict'

import { SortOption } from '~/pages/payments/components/sort-config-select'

import { PaymentLinksBaseInfo } from '~/api/payment-links'
import { ContractStatementInfo } from '~/api/statements'
import res from './res'

const options = computed(() => {
    const titles = res().sortOptions

    return strict<
        Array<{ title: string; items: Array<SortOption<PaymentLinksBaseInfo>> }>
    >([
        {
            title: res().sortOptions.createdDate,
            items: [
                {
                    field: 'createdDate',
                    direction: 'desc',
                    title: titles.desc.createdDate
                },
                {
                    field: 'createdDate',
                    direction: 'asc',
                    title: titles.asc.createdDate
                }
            ]
        },
        {
            title: res().sortOptions.expirationDate,
            items: [
                {
                    field: 'expireDate',
                    direction: 'desc',
                    title: titles.desc.expirationDate
                },
                {
                    field: 'expireDate',
                    direction: 'asc',
                    title: titles.asc.expirationDate
                }
            ]
        },
        {
            title: res().sortOptions.order,
            items: [
                {
                    field: 'invoiceId',
                    direction: 'desc',
                    title: titles.desc.order
                },
                {
                    field: 'invoiceId',
                    direction: 'asc',
                    title: titles.asc.order
                }
            ]
        },
        {
            title: res().sortOptions.amount,
            items: [
                {
                    field: 'amount',
                    direction: 'desc',
                    title: titles.desc.amount
                },
                {
                    field: 'amount',
                    direction: 'asc',
                    title: titles.asc.amount
                }
            ]
        },
        {
            title: res().sortOptions.notifierContact,
            items: [
                {
                    field: 'notifierContact',
                    direction: 'desc',
                    title: titles.desc.notifierContact
                },
                {
                    field: 'notifierContact',
                    direction: 'asc',
                    title: titles.asc.notifierContact
                }
            ]
        },
        {
            title: res().sortOptions.recipientContactSms,
            items: [
                {
                    field: 'recipientContactSms',
                    direction: 'desc',
                    title: titles.desc.recipientContactSms
                },
                {
                    field: 'recipientContactSms',
                    direction: 'asc',
                    title: titles.asc.recipientContactSms
                }
            ]
        },
        {
            title: res().sortOptions.language,
            items: [
                {
                    field: 'language',
                    direction: 'desc',
                    title: titles.desc.language
                },
                {
                    field: 'language',
                    direction: 'asc',
                    title: titles.asc.language
                }
            ]
        },
        {
            title: res().sortOptions.statusId,
            items: [
                {
                    field: 'statusId',
                    direction: 'desc',
                    title: titles.desc.statusId
                },
                {
                    field: 'statusId',
                    direction: 'asc',
                    title: titles.asc.statusId
                }
            ]
        }
    ])
})

export default options

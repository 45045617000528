import { PaymentStatus } from '~/api'

export default {

    [PaymentStatus.auth]: {
        color: '#FFB600'
    },
    [PaymentStatus.charged]: {
        color: '#05B9A6'
    },
    [PaymentStatus.cancelled]: {
        color: '#BDBDBD'
    },
    [PaymentStatus.refunded]: {
        color: '#F42B74'
    },
    [PaymentStatus.rejected]: {
        color: '#F42B74'
    },
    [PaymentStatus.CANCEL_OLD]: {
        color: '#BDBDBD'
    },
    [PaymentStatus.TIMEOUT_3D]: {
        color: '#F42B74'
    },
    [PaymentStatus.failed]: {
        color: '#F42B74'
    },
    [PaymentStatus.REFUND_IN_PROCESSING_QR]: {
        color: '#FFB600'
    },
    [PaymentStatus.PENDING]: {
        color: '#FFB600'
    },
    [PaymentStatus.SUCCESS]: {
        color: '#05B9A6'
    },
    [PaymentStatus.VERIFIED]: {
        color: '#00c2ff'
    }
}

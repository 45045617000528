import locale from '~/utils/locale'

import { P2PTransfer, P2PStatus } from '~/api'

export default locale.create<{
    title: string
    ranges: {
        today: string
        yesterday: string
        thisWeek: string
        priorWeek: string
        thisMonth: string
        priorMonth: string
        thisQuarter: string
        customPeriod: string
    },
    anyPaymentStatus: string
    paymentStatuses: {
        [key in P2PStatus]: string
    },
    sortBy: string
    filtersPlaceholder: string
    dataFields: {
        full: {
            [key in keyof P2PTransfer]?: string
        },
        short: {
            [key in keyof P2PTransfer]?: string
        },
        groups: {
            location: string,
            paymentPage: string
        },
        geolocation: string
    }
    sortOptions: {
        accountId: string
        amount: string
        amountBonus: string
        approvalCode: string
        cardMask: string
        senderEmail: string
        senderCardMask: string
        receiverCardMask: string
        chargeback: string
        date: string
        description: string
        email: string
        fee: string
        orderId: string
        invoiceIdAlt: string
        orgAmount: string
        payerName: string
        payerPhone: string
        paymentType: string
        payoutAmount: string
        payoutDate: string
        postLink: string
        purpose: string
        qrReference: string
        reference: string
        resultMessage: string
        status: string
        type: string
        user: string
        user_ip: string
        asc: {
            accountId: string,
            amount: string,
            amountBonus: string,
            approvalCode: string,
            cardMask: string,
            senderEmail: string,
            senderCardMask: string,
            receiverCardMask: string,
            chargeback: string,
            date: string
            description: string,
            email: string,
            fee: string,
            orderId: string
            invoiceIdAlt: string,
            orgAmount: string,
            payerName: string,
            payerPhone: string,
            paymentType: string,
            payoutAmount: string,
            payoutDate: string,
            postLink: string
            purpose: string,
            qrReference: string,
            reference: string,
            resultMessage: string,
            status: string
            type: string,
            user: string,
            user_ip: string
        },
        desc: {
            accountId: string,
            amount: string,
            amountBonus: string,
            approvalCode: string,
            cardMask: string,
            senderEmail: string,
            senderCardMask: string,
            receiverCardMask: string,
            chargeback: string,
            date: string
            description: string,
            email: string,
            fee: string,
            orderId: string
            invoiceIdAlt: string,
            orgAmount: string,
            payerName: string,
            payerPhone: string,
            paymentType: string,
            payoutAmount: string,
            payoutDate: string,
            postLink: string
            purpose: string,
            qrReference: string,
            reference: string,
            resultMessage: string,
            status: string
            type: string,
            user: string,
            user_ip: string
        }
    }
    installments: {
        full: {
            '3m': string
            '6m': string
            '9m': string
            '12m': string
        },
        short: {
            '3m': string
            '6m': string
            '9m': string
            '12m': string
        }
    }
    secureValues: {
        true: string
        false: string
    }
    partialSum: {
        isRequired: string
        constraints: string
    }
    operations: {
        fullCharge: {
            title: string
            itemError: (paymentId) => string
        },
        partialCharge: {
            title: string
            itemError: (paymentId) => string
        },
        cancel: {
            title: string
            itemError: (paymentId) => string
        },
        fullRefund: {
            title: string
            itemError: (paymentId) => string
        },
        partialRefund: {
            title: string
            itemError: (paymentId) => string
        }
    }
    postLinkStatus: {
        all: string
        ok: string
        fail: string
    }
    collapseSummary: (range: string | (() => string)) => string
    errors: {
        summaryLoadError: string
        paymentsLoadError: string
    }
}>()
